import React, { useState } from 'react'
import { TextField, Button, Typography } from '@material-ui/core'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'

interface Props {
    sendMessage: (text: string) => void
}

export const PushNotificationsScreen: React.FC<Props> = ({ sendMessage }) => {
    const [value, setvalue] = useState('')
    useDrawerTitle('Benachrichtigungen')

    return (
        <div>
            <Typography>
                Wähle den Text für deine Push-Benachrichtigung und sende sie direkt ab.
            </Typography>
            <TextField value={value} onChange={(evt) => setvalue(evt.currentTarget.value)} />
            <Button onClick={() => sendMessage(value)}>Senden</Button>
        </div>
    )
}
