import * as React from 'react'
import { Box, Typography } from '@material-ui/core'

export const EmptyFoodList: React.FC = () => {
    return (
        <Box textAlign="center" marginTop={1}>
            <Typography variant="h6">Keine Einträge</Typography>
            <Typography variant="subtitle1">
                Füge jetzt deine Mahlzeiten des Tages hinzu! :-)
            </Typography>
        </Box>
    )
}
