import React from 'react'
import { useGetStudiesWithImageMetadata } from '../../../graphql/hooks/study'
import { SpacePerStudy } from './SpacePerStudy'
import sortBy from 'lodash.sortby'

export const SpacePerStudyContainer: React.FC = () => {
    const { data, loading } = useGetStudiesWithImageMetadata()
    const sortedStudies = sortBy(
        data?.getStudies || [],
        (study) => (study.imagesMetadata?.totalSizeInMB || 0) * -1
    )
    return <SpacePerStudy isLoading={loading} studies={sortedStudies} />
}
