import {
    Box,
    Button,
    Checkbox,
    FilledInput,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Paper,
    Typography,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import CauLogo from '../../assets/cau.jpeg'
import { spacingMedium } from '../../styles/spacing'

interface Props {
    handleLogin: (username: string, password: string) => void
    handleRegisterProband: (username: string, password: string) => Promise<any>
    hasSuccessfullyRegistered: boolean
}

const InputWrapper = styled(Box)({
    margin: '0 auto',
    maxWidth: 400,
    width: '100%',
})

const CustomLink = styled(Link)`
    text-decoration: none;
`

const LinkWrapper = styled.div``

const Logo = styled.img`
    display: block;
    max-width: 200px;
    max-height: 121px;
    width: auto;
    height: auto;
    margin: 0 auto;
`

const Wrapper = styled.div`
    max-width: 400px;
    margin: 0 auto;
    margin-top: 32px;
`

interface FormError {
    password: string | null
}

export const Login: React.FC<Props> = ({
    handleLogin,
    handleRegisterProband,
    hasSuccessfullyRegistered,
}) => {
    const loginButtonRef = React.useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (hasSuccessfullyRegistered) {
            setisRegister(false)
            setPassword('')
            setPasswordConfirm('')
        }
    }, [hasSuccessfullyRegistered])

    useEffect(() => {
        const handleEnter = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                loginButtonRef.current?.click()
            }
        }
        document.addEventListener('keydown', handleEnter)
        return () => document.removeEventListener('keydown', handleEnter)
    }, [])

    const [showPassword, setshowPassword] = useState(false)
    const [showPasswordConfirm, setshowPasswordConfirm] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [dsgvoIsConfirmed, setDsgvoIsConfirmed] = useState(false)
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [isRegister, setisRegister] = useState(false)
    const [formError, setformError] = useState<FormError>({ password: null })

    const saveIsDisabled = !(username.length > 0 && password.length > 0)
    const registerIsDisabled = !(
        username.length > 0 &&
        password.length > 0 &&
        passwordConfirm === password &&
        dsgvoIsConfirmed
    )

    const handleRegisterSubmit = (username: string, password: string) => {
        if (password.length < 6) {
            setformError({
                ...formError,
                password: 'Passwort muss aus mindestens 6 Zeichen bestehen!',
            })
        } else {
            handleRegisterProband(username, password).then(() => setisRegister(false))
        }
    }

    return (
        <Wrapper>
            <Paper style={{ marginRight: '8px', marginLeft: '8px' }} elevation={2}>
                <Box display="flex" flexDirection="column" justifyContent="space-evenly">
                    <Logo style={{ marginTop: '16px' }} src={CauLogo} />
                    <Box margin={1}>
                        <Typography align="center" variant="h5">
                            Institut für Humanernährung und Lebensmittelkunde
                        </Typography>
                    </Box>
                    <InputWrapper>
                        <Box m={2}>
                            <FormControl fullWidth={true} variant="filled">
                                <InputLabel htmlFor="filled-adornment-password">
                                    Probandennummer
                                </InputLabel>
                                <FilledInput
                                    value={username}
                                    onChange={(e) => setUsername(e.currentTarget.value)}
                                    type={'text'}
                                />
                            </FormControl>
                        </Box>
                        <Box m={2}>
                            <FormControl fullWidth={true} variant="filled">
                                <InputLabel htmlFor="filled-adornment-password">
                                    Password
                                </InputLabel>
                                <FilledInput
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    error={!!formError.password}
                                    onChange={(e) => {
                                        setformError({ ...formError, password: null })
                                        setPassword(e.currentTarget.value)
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setshowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {formError.password && (
                                    <FormHelperText>{formError.password}</FormHelperText>
                                )}
                            </FormControl>

                            {isRegister && (
                                <Box mt={2}>
                                    <FormControl fullWidth={true} variant="filled">
                                        <InputLabel htmlFor="filled-adornment-password">
                                            Password bestätigen
                                        </InputLabel>
                                        <FilledInput
                                            type={showPasswordConfirm ? 'text' : 'password'}
                                            value={passwordConfirm}
                                            onChange={(e) =>
                                                setPasswordConfirm(e.currentTarget.value)
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() =>
                                                            setshowPasswordConfirm(
                                                                !showPasswordConfirm
                                                            )
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPasswordConfirm ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>

                                    <FormControlLabel
                                        style={{ marginTop: spacingMedium }}
                                        control={
                                            <Checkbox
                                                checked={dsgvoIsConfirmed}
                                                onChange={() =>
                                                    setDsgvoIsConfirmed(!dsgvoIsConfirmed)
                                                }
                                                name="checkedA"
                                            />
                                        }
                                        label={
                                            <Typography>
                                                Ja, ich stimme den{' '}
                                                <Link to="/datenschutz">
                                                    Datenschutzbestimmungen
                                                </Link>{' '}
                                                zu.
                                            </Typography>
                                        }
                                    />
                                </Box>
                            )}
                        </Box>
                        <Box m={2} marginTop={4} display="flex" justifyContent="space-between">
                            <Button
                                onClick={() => setisRegister(!isRegister)}
                                color="primary"
                                ref={loginButtonRef}
                            >
                                {isRegister ? 'zur Anmeldung' : 'Account erstellen'}
                            </Button>

                            <Button
                                onClick={() =>
                                    isRegister
                                        ? handleRegisterSubmit(username, passwordConfirm)
                                        : handleLogin(username, password)
                                }
                                variant="contained"
                                color="primary"
                                disabled={isRegister ? registerIsDisabled : saveIsDisabled}
                                ref={loginButtonRef}
                            >
                                {isRegister ? 'Registrieren' : 'Anmelden'}
                            </Button>
                        </Box>
                    </InputWrapper>
                </Box>
            </Paper>
            <Box display="flex" mt={2} ml={1} mr={1} justifyContent="flex-end">
                <LinkWrapper>
                    <CustomLink to="/support">
                        <Typography style={{ marginRight: '16px' }} color="primary" variant="body2">
                            Hilfe
                        </Typography>
                    </CustomLink>
                </LinkWrapper>
                <LinkWrapper>
                    <CustomLink to="/datenschutz">
                        <Typography color="primary" variant="body2">
                            Datenschutz
                        </Typography>
                    </CustomLink>
                </LinkWrapper>
                <LinkWrapper style={{ marginLeft: 8 }}>
                    <CustomLink to="/impressum">
                        <Typography color="primary" variant="body2">
                            Impressum
                        </Typography>
                    </CustomLink>
                </LinkWrapper>
            </Box>
        </Wrapper>
    )
}
