import React from 'react'
import { PushNotificationsScreen } from './PushNotificationsScreen'
import { useSendPushNotification } from '../../graphql/hooks/pushNotification'

export const PushNotificationsContainer: React.FC = () => {
    const sendPush = useSendPushNotification()
    const handleSend = (text: string) => {
        sendPush({
            variables: {
                text,
            },
        })
    }
    return <PushNotificationsScreen sendMessage={handleSend} />
}
