import React from 'react'
import { useParams } from 'react-router-dom'
import { useGetStudies } from '../../../graphql/hooks/study'
import { ManagedBy } from './ManagedBy'
import { useRemoveAdminFromStudy } from '../../../graphql/hooks/user'

interface Props {
    studyTitle?: string
}

export const ManagedByContainer: React.FC<Props> = ({ studyTitle }) => {
    const { data } = useGetStudies()

    const removeAdminFromStudyHook = useRemoveAdminFromStudy()

    const { id } = useParams<{ id: string | undefined }>()

    const removeAdminFromStudy = (adminId: string, studyId: string) => {
        removeAdminFromStudyHook({
            variables: {
                userId: adminId,
                studyId,
            },
        })
    }
    return (
        <ManagedBy
            removeAdminFromStudy={removeAdminFromStudy}
            study={data?.getStudies?.find((study) => study.id === id)}
        />
    )
}
