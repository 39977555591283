import { UiState, initialUiState } from './ui-state'
import { initialDataState, DataState } from './data-state'

export type AppState = Readonly<{
    ui: UiState
    data: DataState
}>

export const initialAppState: AppState = {
    ui: initialUiState,
    data: initialDataState,
}
