import { CircularProgress, Box } from '@material-ui/core'
import styled, { StyledComponent } from 'styled-components'

interface PreviewImageProps {
    readonly isLoading: boolean
}
export const PreviewImage: StyledComponent<'img', any, PreviewImageProps, any> = styled.img<
    PreviewImageProps
>`
    width: 100%;
    object-fit: contain;
    background: rgba(0, 0, 0, 0.6);
    -webkit-filter: ${(props) => (props.isLoading ? 'brightness(50%)' : 'brightness(100%)')};
`

export const ImageWrapper = styled(Box)`
    position: relative;
    text-align: center;
    max-width: 200px;
    max-height: 300px;
`

export const UploadingProgess = styled(CircularProgress)`
    position: absolute;
    top: 40%;
    left: 40%;
`
