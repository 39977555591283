import { Page } from '../state/ui-state'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { SetPageAction, setPageAction } from '../actions/ui'

export const usePage = (page: Page) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch<SetPageAction>(setPageAction(page))
    }, [dispatch, page])
}
