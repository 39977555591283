import * as React from 'react'
import { Box, Tooltip } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/ArrowLeft'
import ArrowRight from '@material-ui/icons/ArrowRight'
import DateFnsUtils from '@date-io/date-fns'
import { useState } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import deLocale from 'date-fns/locale/de'
import { addDays, subDays, isToday, format, parseISO } from 'date-fns'
import { HistoryDayOverview } from '../../components/History/HistoryDayOverview'
import { StyledPicker } from './HistoryScreen.styles'
import { usePage } from '../../hooks/usePage'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'

const Spacer = styled.div`
    width: 24px;
    height: 24px;
`

export const HistoryScreen: React.FC = () => {
    const { day } = useParams<{ day: string | undefined }>()
    const [selectedDay, setSelectedDay] = useState(day ? parseISO(day) : new Date())
    usePage('history')
    useDrawerTitle('Historie')
    const history = useHistory()

    const handleSelection = (date: Date) => {
        setSelectedDay(date)
        history.push(`/history/${format(date, 'yyyy-MM-dd')}`)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <Box mb={1} justifyContent="space-between" display="flex" alignItems="center">
                <Tooltip
                    title="Tag zurück"
                    onClick={() => handleSelection(subDays(selectedDay, 1))}
                >
                    <ArrowLeft color="primary" />
                </Tooltip>
                <StyledPicker
                    variant="inline"
                    inputVariant="standard"
                    value={selectedDay}
                    disableFuture
                    color="primary"
                    onChange={(evt) => evt && handleSelection(new Date(evt))}
                />
                {!isToday(selectedDay) ? (
                    <Tooltip
                        onClick={() => handleSelection(addDays(selectedDay, 1))}
                        title="Tag vor"
                    >
                        <ArrowRight color="primary" />
                    </Tooltip>
                ) : (
                    <Spacer />
                )}
            </Box>
            <HistoryDayOverview selectedDay={selectedDay} />
        </MuiPickersUtilsProvider>
    )
}
