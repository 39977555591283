import { LinearProgress, Paper, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { getAvailableSpaceInMegaByte_getAvailableSpaceInMegaByte as Space } from '../../graphql/generated/getAvailableSpaceInMegaByte'
import { spacingMedium, spacingSmall } from '../../styles/spacing'
import { SpacePerStudyContainer } from './space-per-study/SpacePerStudy.container'

interface Props {
    readonly availableSpace: Space
}

const CustomProgress = styled(LinearProgress)`
    height: 16px;
    border-radius: 4px;
`

const SpaceHeader = styled(Typography)`
    font-weight: bold;
    font-size: 1.15rem;
    margin-bottom: ${spacingMedium};
`

const GigaByteSpan = styled.span`
    font-weight: bold;
`

export const SpaceScreen: React.FC<Props> = ({ availableSpace }) => {
    const availableSpacePercentage =
        100 - Math.round((availableSpace.freeInMB / availableSpace.totalInMB) * 100)

    const usedSpaceInGB = Math.round((availableSpace.totalInMB - availableSpace.freeInMB) / 1024)
    const totalSpaceInGB = Math.round(availableSpace.totalInMB / 1024)

    return (
        <div>
            <Typography style={{ marginBottom: spacingMedium }} variant="h4">
                Speicherverbrauch
            </Typography>

            <Paper style={{ padding: spacingMedium }}>
                <SpaceHeader variant="h6">Insgesamt belegter Speicherplatz</SpaceHeader>
                <CustomProgress variant="determinate" value={availableSpacePercentage} />
                <Typography style={{ marginTop: spacingSmall }} variant="subtitle2">
                    <GigaByteSpan>{usedSpaceInGB}GB</GigaByteSpan> von{' '}
                    <GigaByteSpan>{totalSpaceInGB}GB</GigaByteSpan> belegt
                </Typography>
            </Paper>
            <SpacePerStudyContainer />
        </div>
    )
}
