import * as React from 'react'
import { FoodType } from '../../graphql/generated/globalTypes'
import { useGetAllMyFood } from '../../graphql/hooks/food'
import { millisStringToDate, isBetweenStartAndEndOfDay } from '../../utils/date'
import { HistoryBox } from './HistoryBox'
import { Box, Typography } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { SetErrorNotificationAction, setErrorNotificationAction } from '../../actions/ui'

export type Props = Readonly<{
    selectedDay: Date
}>

export const HistoryDayOverview: React.FC<Props> = ({ selectedDay }) => {
    const data = useGetAllMyFood()
    const dispatch = useDispatch()

    const getFoodForSelectedDayAndType = (typeOfFood: FoodType) =>
        data?.getAllMyFood
            ?.filter((food) => {
                try {
                    return (
                        isBetweenStartAndEndOfDay(millisStringToDate(food.time), selectedDay) &&
                        food.type === typeOfFood
                    )
                } catch (error) {}
            })
            .sort((a, b) => (a.time > b.time ? 1 : -1)) || []

    const getFoodForSelectedDay = () =>
        data?.getAllMyFood?.filter((food) => {
            try {
                return isBetweenStartAndEndOfDay(millisStringToDate(food.time), selectedDay)
            } catch (error: any) {
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(error.message))
            }
        }) || []

    if (getFoodForSelectedDay().length === 0) {
        return (
            <Box display="flex" mt={2} justifyContent="center">
                <Typography variant="body1">Keine Einträge für den gewählten Tag</Typography>
            </Box>
        )
    }
    return (
        <>
            <HistoryBox
                food={getFoodForSelectedDayAndType(FoodType.meal)}
                title="Mahlzeiten"
                type={FoodType.meal}
            />
            <HistoryBox
                food={getFoodForSelectedDayAndType(FoodType.snack)}
                title="Snacks"
                type={FoodType.snack}
            />
            <HistoryBox
                food={getFoodForSelectedDayAndType(FoodType.drink)}
                title="Getränke"
                type={FoodType.drink}
            />
        </>
    )
}
