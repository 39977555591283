import { FoodType } from '../../../graphql/generated/globalTypes'
import { Food } from '../../../models'
import { Selection } from '../../../models/Selection'

export const filterValuesForAutocompleteDescription = (food: Food[], type: FoodType): Selection[] =>
    food
        .filter((food) => food.type === type)
        .filter(
            (food, index, self) =>
                index === self.findIndex((t) => t.description === food.description)
        )
        .map((food) => ({ value: food.description, label: food.description }))

export const filterValuesForAutocompleteFoodBrands = (food: Food[], type: FoodType): Selection[] =>
    food
        .filter((food) => food.type === type && food.foodBrands !== null)
        .filter(
            (food, index, self) => index === self.findIndex((t) => t.foodBrands === food.foodBrands)
        )
        .map((food) => ({ value: food.foodBrands ?? '', label: food.foodBrands ?? '' }))
