import React from 'react'
import { Paper, Box, Typography } from '@material-ui/core'
import { Title } from '../../screens/Help-Login/HelpLoginScreen'
import { BackButton } from '../../components/BackButton/BackButton'

export const DataProtectionScreen: React.FC = () => {
    return (
        <Box maxWidth={800} padding={1} margin="0 auto" marginTop={2}>
            <BackButton />
            <Title variant="h4">Datenschutzerklärung</Title>
            <Paper style={{ padding: '16px' }}>
                <Typography variant="body1">
                    <h2>Einleitung</h2>
                    <p>
                        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären,
                        welche Arten Ihrer personenbezogenen Daten (nachfolgend auch kurz als
                        "Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang im Rahmen
                        der Bereitstellung unserer Applikation verarbeiten.
                    </p>
                    <p>Stand: 1. Oktober 2020</p>
                    <h2>Inhaltsübersicht</h2>
                    <ul className="index">
    <li><a className="index-link" href="#m1870">Einleitung</a></li>
    <li><a className="index-link" href="#m3">Verantwortlicher</a></li>
    <li><a className="index-link" href="#mOverview">Übersicht der Verarbeitungen</a></li>
    <li><a className="index-link" href="#m13">Maßgebliche Rechtsgrundlagen</a></li>
    <li><a className="index-link" href="#m27">Sicherheitsmaßnahmen</a></li>
    <li><a className="index-link" href="#m134">Einsatz von Cookies</a></li>
    <li><a className="index-link" href="#m1643">Push-Nachrichten</a></li>
    <li><a className="index-link" href="#m12">Löschung von Daten</a></li>
    <li>
        <a className="index-link" href="#m15">Änderung und Aktualisierung der Datenschutzerklärung</a>
    </li>
    <li><a className="index-link" href="#m10">Rechte der betroffenen Personen</a></li>
    <li><a className="index-link" href="#m42">Begriffsdefinitionen</a></li>
</ul>
<h2 id="m3">Verantwortlicher</h2>
<p>
    Institut für Humanernährung<br />Christian-Albrechts-Universität zu Kiel<br />Prof. Dr. Dr. Anja
    Bosy-Westphal<br />Düsternbrooker Weg 17, 24105 Kiel
</p>
<p>
    <strong>E-Mail-Adresse: </strong>
    <a href="mailto:studie@nutrition.uni">studie@nutrition.uni-kiel.de </a>
</p>
<p>
    <strong>Impressum: </strong>
    <a href="https://meal-tracking.nutrition.uni-kiel.de/impressum" rel="noopener noreferrer" target="_blank"
        >meal-tracking.nutrition.uni-kiel.de/impressum</a
    >
</p>
<h2 id="mOverview">Übersicht der Verarbeitungen</h2>
<p>
    Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer
    Verarbeitung zusammen und verweist auf die betroffenen Personen.
</p>
<h3>Arten der verarbeiteten Daten</h3>
<ul>
    <li>Meta-/Kommunikationsdaten (z.B. Geräte-Informationen).</li>
</ul>
<h3>Kategorien betroffener Personen</h3>
<ul>
    <li>Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).</li>
</ul>
<h3>Zwecke der Verarbeitung</h3>
<ul>
    <li>Vertragliche Leistungen und Service.</li>
</ul>
<h3 id="m13">Maßgebliche Rechtsgrundlagen</h3>
<p>
    Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren
    Basis wir die personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu
    den Regelungen der DSGVO die nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und
    Sitzland gelten können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen maßgeblich
    sein, teilen wir Ihnen diese in der Datenschutzerklärung mit.
</p>
<ul>
    <li>
        <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO)</strong> - Die betroffene Person hat
        ihre Einwilligung in die Verarbeitung der sie betreffenden personenbezogenen Daten für einen
        spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
    </li>

    <li>
        <strong>Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO)</strong> - Die
        Verarbeitung ist zur Wahrung der berechtigten Interessen des Verantwortlichen oder eines
        Dritten erforderlich, sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der
        betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
    </li>
</ul>
<p>
    <strong>Nationale Datenschutzregelungen in Deutschland</strong>: Zusätzlich zu den
    Datenschutzregelungen der Datenschutz-Grundverordnung gelten nationale Regelungen zum
    Datenschutz in Deutschland. Hierzu gehört insbesondere das Gesetz zum Schutz vor Missbrauch
    personenbezogener Daten bei der Datenverarbeitung (Bundesdatenschutzgesetz – BDSG). Das BDSG
    enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum Recht auf Löschung, zum
    Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
    Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung
    im Einzelfall einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke
    des Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung,
    Durchführung oder Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von
    Beschäftigten. Ferner können Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung
    gelangen.
</p>
<h2 id="m27">Sicherheitsmaßnahmen</h2>
<p>
    Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der
    Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der
    Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der
    Bedrohung der Rechte und Freiheiten natürlicher Personen geeignete technische und
    organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau zu gewährleisten.
</p>
<p>
    Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und
    Verfügbarkeit von Daten durch Kontrolle des physischen und elektronischen Zugangs zu den Daten
    als auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der Sicherung der
    Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine
    Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf die Gefährdung der
    Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei
    der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
    Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
</p>
<p>
    <strong>SSL-Verschlüsselung (https)</strong>: Um Ihre via unser Online-Angebot übermittelten
    Daten zu schützen, nutzen wir eine SSL-Verschlüsselung. Sie erkennen derart verschlüsselte
    Verbindungen an dem Präfix https:// in der Adresszeile Ihres Browsers.
</p>

<h2 id="m134">Einsatz von Cookies</h2>
<p>
    Cookies sind Textdateien, die Daten von besuchten Websites oder Domains enthalten und von einem
    Browser auf dem Computer des Benutzers gespeichert werden. Ein Cookie dient in erster Linie
    dazu, die Informationen über einen Benutzer während oder nach seinem Besuch innerhalb eines
    Onlineangebotes zu speichern. Zu den gespeicherten Angaben können z.B. die Spracheinstellungen
    auf einer Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut
    wurde, gehören. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die
    gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer
    Onlinekennzeichnungen gespeichert werden, auch als "Nutzer-IDs" bezeichnet)
</p>
<p>
    Wir verwenden ledigliche notwendige Cookies, die zum Betrieb der Website erforderlich sind. Im
    Detail ist dies ein permanenter Cookie zum Speichern des Loginstatus. Weitere Cookies werden
    nicht verwendet.
</p>

<p>
    <strong>Hinweise zu Rechtsgrundlagen: </strong> Die mithilfe von Cookies verarbeiteten Daten
    werden auf Grundlage unserer berechtigten Interessen (z.B. an einem betriebswirtschaftlichen
    Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet oder, wenn der Einsatz von
    Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
</p>
<p>
    <strong>Speicherdauer: </strong>Sofern wir Ihnen keine expliziten Angaben zur Speicherdauer von
    permanenten Cookies mitteilen (z. B. im Rahmen eines sog. Cookie-Opt-Ins), gehen Sie bitte davon
    aus, dass die Speicherdauer bis zu zwei Jahre betragen kann.
</p>
<p>
    <strong>Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-Out): </strong> Abhängig davon, ob
    die Verarbeitung auf Grundlage einer Einwilligung oder gesetzlichen Erlaubnis erfolgt, haben Sie
    jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen oder der Verarbeitung Ihrer
    Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als "Opt-Out" bezeichnet). Sie
    können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem
    Sie die Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres
    Onlineangebotes eingeschränkt werden kann). Ein Widerspruch gegen den Einsatz von Cookies zu
    Zwecken des Onlinemarketings kann auch mittels einer Vielzahl von Diensten, vor allem im Fall
    des Trackings, über die Webseiten
    <a href="https://optout.aboutads.info" rel="noopener noreferrer" target="_blank">https://optout.aboutads.info</a> und
    <a href="https://www.youronlinechoices.com/" rel="noopener noreferrer" target="_blank"
        >https://www.youronlinechoices.com/</a
    >
    erklärt werden. Daneben können Sie weitere Widerspruchshinweise im Rahmen der Angaben zu den
    eingesetzten Dienstleistern und Cookies erhalten.
</p>
<ul className="m-elements">
    <li>
        <strong>Verarbeitete Datenarten:</strong> Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
    </li>
    <li>
        <strong>Betroffene Personen:</strong> Nutzer (z.B. Webseitenbesucher, Nutzer von
        Onlinediensten).
    </li>
    <li>
        <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
        Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f. DSGVO).
    </li>
</ul>
<h2 id="m1643">Push-Nachrichten</h2>
<p>
    Mit der Zustimmung der Nutzer, können wir den Nutzern so genannte "Push-Benachrichtigungen"
        zusenden. Dabei handelt es sich um Nachrichten, die auf den Bildschirmen, Endgeräten oder in
        Browsern der Nutzer angezeigt werden, auch wenn unser Onlinedienst gerade nicht aktiv
        genutzt wird. 
</p>
<p>
    Um sich für die Push-Nachrichten anzumelden, müssen Nutzer die Abfrage ihres Browsers bzw.
    Endgerätes zum Erhalt der Push-Nachrichten bestätigen. 
    <span className="dsg-license-content-blurred de">
    Wir verwenden für die Push-Benachrichtigungen die Dienste Firebase Cloud Messaging der Firma Google (Android). 
    Auf iOS Geräten kommen keine Push-Benachrichtigungen zum Einsatz. Dabei wird von Firebase ein Schlüssel erstellt, der die Kennung der App mit einer Gerätekennung kombiniert.
     Dieser Schlüssel wird auf unserer Datenbank gespeichert, falls Sie den Benachrichtigungen zugestimmt haben. Die Firebase-Server können keinerlei Rückschluss auf die Anfragen von Nutzenden ziehen oder sonstige Daten ermitteln, die mit ihrer Person im Zusammenhang stehen. Firebase dient ausschließlich als Übermittler.
    </span>
</p>
<div className="dsg-license-content-blurred">
    <ul className="m-elements">
        <li><strong>Zwecke der Verarbeitung:</strong> Vertragliche Leistungen und Service.</li>
        <li>
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1 lit. a DSGVO),
            Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b. DSGVO).
        </li>
    </ul>
</div>
<h2 id="m12">Löschung von Daten</h2>
<p>
    Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald
    deren zur Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse
    entfallen (z.B., wenn der Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den
    Zweck nicht erforderlich sind).
</p>
<p>
    Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke
    erforderlich sind, wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden
    gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
    steuerrechtlichen Gründen aufbewahrt werden müssen oder deren Speicherung zur Geltendmachung,
    Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
    natürlichen oder juristischen Person erforderlich ist.
</p>
<p>
    Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der
    einzelnen Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
</p>
<h2 id="m15">Änderung und Aktualisierung der Datenschutzerklärung</h2>
<p>
    Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir
    passen die Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten
    Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die Änderungen
    eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
    Benachrichtigung erforderlich wird.
</p>
<p>
    Sofern wir in dieser Datenschutzerklärung Adressen und Kontaktinformationen von Unternehmen und
    Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
    können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
</p>
<h2 id="m10">Rechte der betroffenen Personen</h2>
<p>
    Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus
    Art. 15 bis 21 DSGVO ergeben:
</p>
<ul>
    <li>
        <strong
            >Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden
            personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
            Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes
            Profiling. Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um
            Direktwerbung zu betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
            Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke derartiger Werbung
            einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
            Verbindung steht.</strong
        >
    </li>
    <li>
        <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das Recht, erteilte
        Einwilligungen jederzeit zu widerrufen.
    </li>
    <li>
        <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine Bestätigung darüber zu verlangen,
        ob betreffende Daten verarbeitet werden und auf Auskunft über diese Daten sowie auf weitere
        Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.
    </li>
    <li>
        <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den gesetzlichen Vorgaben
        das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie
        betreffenden unrichtigen Daten zu verlangen.
    </li>
    <li>
        <strong>Recht auf Löschung und Einschränkung der Verarbeitung:</strong> Sie haben nach
        Maßgabe der gesetzlichen Vorgaben das Recht, zu verlangen, dass Sie betreffende Daten
        unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
        Einschränkung der Verarbeitung der Daten zu verlangen.
    </li>
    <li>
        <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das Recht, Sie betreffende Daten,
        die Sie uns bereitgestellt haben, nach Maßgabe der gesetzlichen Vorgaben in einem
        strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
        einen anderen Verantwortlichen zu fordern.
    </li>
    <li>
        <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben ferner nach Maßgabe der
        gesetzlichen Vorgaben das Recht, bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes oder des Orts des
        mutmaßlichen Verstoßes Beschwerde einzulegen, wenn Sie der Ansicht sind, dass die
        Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
    </li>
</ul>
<h2 id="m42">Begriffsdefinitionen</h2>
<p>
    In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung
    verwendeten Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im
    Art. 4 DSGVO definiert. Die gesetzlichen Definitionen sind verbindlich. Die nachfolgenden
    Erläuterungen sollen dagegen vor allem dem Verständnis dienen. Die Begriffe sind alphabetisch
    sortiert.
</p>
<ul className="glossary">
    <li>
        <strong>Personenbezogene Daten:</strong> "Personenbezogene Daten“ sind alle Informationen,
        die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden
        "betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen,
        die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
        zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem
        oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen,
        physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen
        Identität dieser natürlichen Person sind.
    </li>
    <li>
        <strong>Verantwortlicher:</strong> Als "Verantwortlicher“ wird die natürliche oder
        juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit
        anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet,
        bezeichnet.
    </li>
    <li>
        <strong>Verarbeitung:</strong> "Verarbeitung" ist jeder mit oder ohne Hilfe automatisierter
        Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit
        personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit
        Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das Löschen.
    </li>
</ul>
<p className="seal">
    <a
        href="https://datenschutz-generator.de/?l=de"
        title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
        target="_blank"
        rel="noopener noreferrer nofollow"
        >Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a
    >
</p>

                </Typography>
            </Paper>
        </Box>
    )
}
