import React from 'react'
import { LoginContainer } from './screens/Login/Login.container'
import { Notification } from './components/Notification/Notification'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Dashboard } from './screens/Dashboard/Dashboard'
import { ProtectedRouteContainer } from './components/ProtectedRoute/ProtectedRoute.container'
import { DrawerLayoutContainer } from './screens/DrawerLayout/DrawerLayout.container'
import CreateFoodScreenContainer from './screens/CreateFood/CreateFoodScreen.container'
import { EditFoodScreenContainer } from './screens/EditFood/EditFoodScreen.container'
import { HistoryScreen } from './screens/History/HistoryScreen'
import { HelpScreen } from './screens/Help/HelpScreen'
import { HelpLoginScreen } from './screens/Help-Login/HelpLoginScreen'
import { DataProtectionScreen } from './screens/Datenschutz/DataProtectionScreen'
import { StudyScreenContainer } from './screens/Study/StudyScreen.container'
import { ProbandsScreen } from './screens/Probands/ProbandsScreen'
import { AdminsScreen } from './screens/Admins/AdminsScreen'
import { PushNotificationsContainer } from './screens/PushNotifications/PushNotifications.container'
import { ImpressumScreen } from './screens/Impressum/ImpressumScreen'
import { SpaceScreenContainer } from './screens/Space/SpaceScreen.container'
import { RootsScreen } from './screens/Roots/RootsScreen'

const App = () => {
    return (
        <>
            <Router>
                <Switch>
                    <Redirect exact from="/" to="/dashboard" />
                    <Route path="/login">
                        <LoginContainer />
                    </Route>
                    <Route path="/support">
                        <HelpLoginScreen />
                    </Route>
                    <Route path="/support">
                        <HelpLoginScreen />
                    </Route>
                    <Route path="/datenschutz">
                        <DataProtectionScreen />
                    </Route>
                    <Route exact path="/impressum">
                        <ImpressumScreen />
                    </Route>
                    <DrawerLayoutContainer>
                        <ProtectedRouteContainer path="/dashboard">
                            <Dashboard />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer path="/new">
                            <CreateFoodScreenContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer path="/edit/:id">
                            <EditFoodScreenContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer path="/history/:day">
                            <HistoryScreen />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer path="/help">
                            <HelpScreen />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer exact onlyAdmin path="/backoffice/studies">
                            <StudyScreenContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyAdmin path="/backoffice/studies/:id">
                            <StudyScreenContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyAdmin path="/backoffice/probands">
                            <ProbandsScreen />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyRoot path="/backoffice/admins">
                            <AdminsScreen />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyRoot path="/backoffice/pushNotifications">
                            <PushNotificationsContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyRoot path="/backoffice/space">
                            <SpaceScreenContainer />
                        </ProtectedRouteContainer>
                        <ProtectedRouteContainer onlyRoot path="/backoffice/roots">
                            <RootsScreen />
                        </ProtectedRouteContainer>
                    </DrawerLayoutContainer>
                </Switch>
            </Router>

            <Notification />
        </>
    )
}

export default App
