import { Box, IconButton, Link, Paper, Tooltip, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import React from 'react'
import styled from 'styled-components'
import { getStudiesWithImageMetadata_getStudies as Study } from '../../graphql/generated/getStudiesWithImageMetadata'
import { spacingMedium, spacingSmall } from '../../styles/spacing'
import { DeletePhotosDialog } from '../Dialog/DeletePhotosDialog'

interface Props {
    readonly study: Study
    readonly showDeleteDialog: boolean
    readonly setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>
    readonly deletePhotos: (fileNames: string[]) => void
}

const Card = styled(Paper)`
    padding: ${spacingMedium};
`

const Header = styled(Typography)`
    font-size: 1.05rem;
    font-weight: bold;
`

export const StudyStorageCard: React.FC<Props> = ({
    study,
    setShowDeleteDialog: setshowDeleteDialog,
    showDeleteDialog,
    deletePhotos,
}) => {
    const studyHasImages = study.imagesMetadata !== null && study.imagesMetadata.countOfImages > 0
    const downloadLink = `${process.env.REACT_APP_BACKEND_BASE_URL}/downloadPhotos/${study.id}`
    return (
        <>
            <Card>
                <Box
                    paddingBottom={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Header>{study.title}</Header>
                    {studyHasImages && (
                        <div>
                            <IconButton
                                onClick={() => setshowDeleteDialog(true)}
                                style={{ padding: 0 }}
                                color={'secondary'}
                            >
                                <Tooltip title="Bilder löschen">
                                    <DeleteIcon />
                                </Tooltip>
                            </IconButton>
                            <IconButton style={{ padding: 0, marginLeft: spacingSmall }}>
                                <Tooltip title="Bilder herunterladen">
                                    <Link href={downloadLink}>
                                        <DownloadIcon />
                                    </Link>
                                </Tooltip>
                            </IconButton>
                        </div>
                    )}
                </Box>
                <Typography>Anzahl Bilder: {study.imagesMetadata?.countOfImages}</Typography>
                <Typography>
                    belegter Speicher: {study.imagesMetadata?.totalSizeInMB.toFixed(2)} MB
                </Typography>
            </Card>
            <DeletePhotosDialog
                onConfirm={() =>
                    study.imagesMetadata?.filenames && deletePhotos(study.imagesMetadata.filenames)
                }
                onDismiss={() => setshowDeleteDialog(false)}
                open={showDeleteDialog}
                photos={study.imagesMetadata?.filenames ?? []}
            />
        </>
    )
}
