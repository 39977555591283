import { Paper, TextField, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import * as React from 'react'
import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { spacingMedium } from '../../styles/spacing'

export type Props = Readonly<{
    onDescriptionChange: (value: string) => void
    value: string
    errorMessage: string | null
    disabled?: boolean
    valuesForAutocomplete: Selection[]
}>

interface Selection {
    value: string
    label: string
}

const Background = styled(Paper)`
    padding: ${spacingMedium};
    margin-top: ${spacingMedium};
`

export const Description: React.FC<Props> = ({
    onDescriptionChange,
    errorMessage,
    valuesForAutocomplete,
    disabled = false,
    value: defaultValue,
}) => {
    return (
        <Background>
            <Typography variant="h6">Beschreibung</Typography>
            <Autocomplete
                freeSolo
                autoSelect
                disabled={disabled}
                placeholder="Beschreibung hinzufügen"
                value={defaultValue}
                noOptionsText="Noch kein Essenseintrag erstellt"
                onChange={(event: ChangeEvent<{}>, value: string | null) =>
                    value && onDescriptionChange(value)
                }
                options={valuesForAutocomplete.map((option) => option.value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Beschreibung"
                        variant="outlined"
                        margin="normal"
                    />
                )}
            />
            {errorMessage && (
                <Typography variant="body2" color="error">
                    {errorMessage}
                </Typography>
            )}
        </Background>
    )
}
