import * as React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { me_me } from '../../graphql/generated/me'
import { UserRole } from '../../graphql/generated/globalTypes'

export interface Props extends RouteProps {
    user: me_me | null
    onlyAdmin?: boolean
    onlyRoot?: boolean
}

export const ProtectedRoute: React.FC<Props> = ({ children, user, onlyAdmin, onlyRoot, ...rest }) => {
    if (onlyAdmin) {
        return (
            <Route {...rest}>
                {user !== null && (user.role === UserRole.admin || user?.role === UserRole.root) ? children : <Redirect to="/login" />}
            </Route>
        )
    }
    if (onlyRoot) {
        return (
            <Route {...rest}>
                {user && user.role === UserRole.root ? children : <Redirect to="/login" />}
            </Route>
        )
    }
    return <Route {...rest}>{user ? children : <Redirect to="/login" />}</Route>
}
