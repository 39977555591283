import { Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'
import { StudyStorageCardContainer } from '../../../components/StudyStorageCard/StudyStorageCard.container'
import { getStudiesWithImageMetadata_getStudies as Study } from '../../../graphql/generated/getStudiesWithImageMetadata'
import { spacingMedium } from '../../../styles/spacing'

interface Props {
    studies: Study[]
    isLoading: boolean
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    grid-gap: ${spacingMedium};
`

export const SpacePerStudy: React.FC<Props> = ({ studies, isLoading }) => {
    return (
        <div>
            <Typography
                style={{ marginBottom: spacingMedium, marginTop: spacingMedium }}
                variant="h4"
            >
                Speicherverbrauch pro Studie
            </Typography>
            <Grid>
                {isLoading && (
                    <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                )}
                {studies.map((study) => (
                    <StudyStorageCardContainer study={study} />
                ))}
            </Grid>
        </div>
    )
}
