import { Paper, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as React from 'react'
import styled from 'styled-components'
import { Selection } from '../../models/Selection'
import { spacingMedium } from '../../styles/spacing'

export type Props = Readonly<{
    onFoodBrandsChange: (value: string) => void
    value: string
    disabled?: boolean
    valuesForAutocomplete: Selection[]
}>

const Background = styled(Paper)`
    padding: ${spacingMedium};
    margin-top: ${spacingMedium};
`

export const FoodBrands: React.FC<Props> = ({
    onFoodBrandsChange,
    disabled = false,
    value: defaultValue,
    valuesForAutocomplete,
}) => {
    const placeholderText = 'Joghurt: Weihenstephan, Tiefkühlpizza: Dr. Oetker, Cola: Fritz-Kola'
    return (
        <Background>
            <div>
                <Typography variant="h6">Lebensmittelmarken</Typography>
                <Typography
                    color={'textSecondary'}
                    variant="body2"
                >{`(z.B. ${placeholderText})`}</Typography>
            </div>
            <Autocomplete
                freeSolo
                autoSelect
                disabled={disabled}
                placeholder={placeholderText}
                value={defaultValue}
                noOptionsText="Noch kein Lebensmittelmarke erstellt"
                onChange={(event: React.ChangeEvent<{}>, value: string | null) =>
                    value && onFoodBrandsChange(value)
                }
                options={valuesForAutocomplete.map((option) => option.value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Lebensmittelmarken"
                        placeholder={placeholderText}
                        variant="outlined"
                        margin="normal"
                    />
                )}
            />
        </Background>
    )
}
