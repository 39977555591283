import React from 'react'
import { CustomCard } from './Study'
import { spacingSmall } from '../../styles/spacing'
import { Typography } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircleOutline'
import styled from 'styled-components'

interface Props {
    onClick: () => void
}

const HorizontalLine = styled.hr`
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const Card = styled(CustomCard)`
    background-color: #f5f5f5;
`

export const AddStudy: React.FC<Props> = ({ onClick }) => {
    return (
        <Card onClick={onClick}>
            <Typography style={{ padding: spacingSmall, lineHeight: '1.2' }} variant="h6">
                Studie hinzufügen
            </Typography>
            <HorizontalLine />
            <IconWrapper>
                <AddCircle color="primary" />
            </IconWrapper>
        </Card>
    )
}
