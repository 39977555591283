import { AppBarItem, NotificationSeverity, Page } from '../state/ui-state'
import { removeGraphQlFromErrorMessage } from '../utils/common'

export type UiAction =
    | SetNotificationAction
    | DismissNotificationAction
    | SetErrorNotificationAction
    | SetDrawerTitleAction
    | SetAppBarItemClicked
    | DismissAppBarClicks
    | SetPageAction
    | SetImageIsUploadingAction
    | SetRtcPossibeAction

export enum ActionType {
    SetNotification = 'set-notificatio-action',
    DismissNotification = 'dismiss-notification-action',
    SetErrorNotification = 'set-error',
    SetDrawerTitle = 'set-drawer-title',
    SetAppBarItemClicked = 'set-app-bar-item-clicked',
    DismissAppBarClicks = 'dismiss-app-bar-clicks',
    SetPage = 'set-page',
    SetImageIsUploading = 'set-is-image-uploading',
    SetIsRtcPossible = 'set-is-rtc-possible',
}

export type NotificationPayload = {
    text: string
    severity: NotificationSeverity
}

export type SetNotificationAction = Readonly<{
    type: ActionType.SetNotification
    notification: NotificationPayload
}>
export type SetDrawerTitleAction = Readonly<{
    type: ActionType.SetDrawerTitle
    title: string
}>
export type SetImageIsUploadingAction = Readonly<{
    type: ActionType.SetImageIsUploading
    isUploading: boolean
}>

export type SetPageAction = Readonly<{
    type: ActionType.SetPage
    page: Page
}>
export type SetRtcPossibeAction = Readonly<{
    type: ActionType.SetIsRtcPossible
    isPossible: boolean
}>

export type SetErrorNotificationAction = Readonly<{
    type: ActionType.SetErrorNotification
    message: string
}>

export type SetAppBarItemClicked = Readonly<{
    type: ActionType.SetAppBarItemClicked
    item: AppBarItem
}>
export type DismissAppBarClicks = Readonly<{
    type: ActionType.DismissAppBarClicks
}>

export type DismissNotificationAction = Readonly<{
    type: ActionType.DismissNotification
}>

export const setNotificationAction = (
    notification: NotificationPayload
): SetNotificationAction => ({
    type: ActionType.SetNotification,
    notification,
})
export const setErrorNotificationAction = (message: string): SetErrorNotificationAction => ({
    type: ActionType.SetErrorNotification,
    message: removeGraphQlFromErrorMessage(message),
})

export const dismissNotificationAction = (): DismissNotificationAction => ({
    type: ActionType.DismissNotification,
})

export const setPageAction = (page: Page): SetPageAction => ({
    type: ActionType.SetPage,
    page,
})

export const setRtcPossibleAction = (isPossible: boolean): SetRtcPossibeAction => ({
    type: ActionType.SetIsRtcPossible,
    isPossible,
})
export const setImageIsUploadingAction = (isUploading: boolean): SetImageIsUploadingAction => ({
    type: ActionType.SetImageIsUploading,
    isUploading,
})

export const DismissAppBarClicks = (): DismissAppBarClicks => ({
    type: ActionType.DismissAppBarClicks,
})

export const SetDrawerTitleAction = (title: string): SetDrawerTitleAction => ({
    type: ActionType.SetDrawerTitle,
    title,
})

export const SetAppBarItemClicked = (item: AppBarItem): SetAppBarItemClicked => ({
    type: ActionType.SetAppBarItemClicked,
    item,
})
