import { useMutation, useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import {
    SetErrorNotificationAction,
    setErrorNotificationAction,
    SetNotificationAction,
    setNotificationAction,
} from '../../actions/ui'
import { createUser, createUserVariables } from '../generated/createUser'
import { deleteUser, deleteUserVariables } from '../generated/deleteUser'
import { getAdmins } from '../generated/getAdmins'
import { getProbands } from '../generated/getProbands'
import { getProbandsWithFoods } from '../generated/getProbandsWithFoods'
import { registerProband, registerProbandVariables } from '../generated/registerProband'
// eslint-disable-next-line
import {
    removeProbandFromStudy,
    removeProbandFromStudyVariables,
} from '../generated/removeProbandFromStudy'
import { resetPassword, resetPasswordVariables } from '../generated/resetPassword'
import { updateUser, updateUserVariables } from '../generated/updateUser'
import {
    CREATE_USER_MUTATION,
    DELETE_USER_MUTATION,
    LOGOUT_MUTATION,
    REGISTER_PROBAND_MUTATION,
    REMOVE_PROBAND_FROM_STUDY,
    RESET_PASSWORD,
    UPDATE_USER_MUTATION,
    REOMVE_ADMIN_FROM_STUDY,
    ADD_ADMIN_TO_STUDY,
    UPDATE_PROBAND_STUDY,
} from '../mutations'
import {
    GET_ADMINS_QUERY,
    GET_PROBANDS_QUERY,
    GET_PROBANDS_WITH_FOOD_QUERY,
    GET_ROOTS_QUERY,
    GET_STUDIES_QUERY,
} from '../queries'
import {
    removeAdminFromStudy,
    removeAdminFromStudyVariables,
} from '../generated/removeAdminFromStudy'
import { addAdminToStudy, addAdminToStudyVariables } from '../generated/addAdminToStudy'
import { getRoots } from '../generated/getRoots'
import { updateProbandStudy, updateProbandStudyVariables } from '../generated/updateProbandStudy'

export const useLogoutMutation = () => {
    const [logout] = useMutation(LOGOUT_MUTATION)

    return logout
}

export const useRegisterMutation = () => {
    const [register] = useMutation<registerProband, registerProbandVariables>(
        REGISTER_PROBAND_MUTATION
    )

    return register
}

export const useCreateUser = () => {
    const dispatch = useDispatch()
    const [createUserMutation] = useMutation<createUser, createUserVariables>(
        CREATE_USER_MUTATION,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
            onCompleted: (res) =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: `Nutzer ${res.createUser.username} erfolgreich erstellt!`,
                    })
                ),
        }
    )

    return createUserMutation
}

export const useDeleteUser = (isCalledFromAdming?: boolean) => {
    const refetchQueries = [
        { query: GET_PROBANDS_QUERY },
        ...(isCalledFromAdming ? [] : [{ query: GET_ADMINS_QUERY }]),
    ]
    const dispatch = useDispatch()
    const [deleteProband] = useMutation<deleteUser, deleteUserVariables>(DELETE_USER_MUTATION, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
        onCompleted: () =>
            dispatch<SetNotificationAction>(
                setNotificationAction({
                    severity: 'success',
                    text: `Nutzer erfolgreich gelöscht!`,
                })
            ),
        refetchQueries,
    })

    return deleteProband
}

export const useGetProbands = () => {
    const dispatch = useDispatch()
    const { data, loading } = useQuery<getProbands>(GET_PROBANDS_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(err?.message || 'Anfrage nicht möglich')
            ),
    })

    return { data, loading }
}

export const useGetAdmins = () => {
    const dispatch = useDispatch()
    const { data, loading } = useQuery<getAdmins>(GET_ADMINS_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(err?.message || 'Anfrage nicht möglich')
            ),
    })

    return { data, loading }
}

export const useGetRoots = () => {
    const dispatch = useDispatch()
    const { data, loading } = useQuery<getRoots>(GET_ROOTS_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(err?.message || 'Anfrage nicht möglich')
            ),
    })

    return { data, loading }
}

export const useGetProbandsWithFood = () => {
    const dispatch = useDispatch()
    const { data, loading } = useQuery<getProbandsWithFoods>(GET_PROBANDS_WITH_FOOD_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(err?.message || 'Anfrage nicht möglich')
            ),
    })

    return { data, loading }
}

export const useRemoveProbandFromStudy = () => {
    const dispatch = useDispatch()
    const [removeProbandFromStudyMutation] = useMutation<
        removeProbandFromStudy,
        removeProbandFromStudyVariables
    >(REMOVE_PROBAND_FROM_STUDY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
        onCompleted: (res) =>
            dispatch<SetNotificationAction>(
                setNotificationAction({
                    severity: 'success',
                    text: `Proband von Studie entfernt`,
                })
            ),
        refetchQueries: [{ query: GET_PROBANDS_QUERY }],
    })

    return removeProbandFromStudyMutation
}
export const useUpdateProbandStudy = () => {
    const dispatch = useDispatch()
    const [updateProbandStudyMutation] = useMutation<
        updateProbandStudy,
        updateProbandStudyVariables
    >(UPDATE_PROBAND_STUDY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
        onCompleted: (res) =>
            dispatch<SetNotificationAction>(
                setNotificationAction({
                    severity: 'success',
                    text: `Proband zu neuer Studie hinzugefügt`,
                })
            ),
        refetchQueries: [{ query: GET_PROBANDS_QUERY }],
    })

    return updateProbandStudyMutation
}

export const useResetPassword = () => {
    const dispatch = useDispatch()
    const [resetProbandPassword] = useMutation<resetPassword, resetPasswordVariables>(
        RESET_PASSWORD,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
            onCompleted: (res) =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: `Passwort erfolgreich zurückgesetzt!`,
                    })
                ),
            refetchQueries: [{ query: GET_PROBANDS_QUERY }],
        }
    )

    return resetProbandPassword
}

export const useUpdateUser = () => {
    const dispatch = useDispatch()
    const [updateUserMutation] = useMutation<updateUser, updateUserVariables>(
        UPDATE_USER_MUTATION,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
            onCompleted: (res) =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: `Nutzer erfolgreich aktualisiert`,
                    })
                ),
            refetchQueries: [{ query: GET_PROBANDS_QUERY }, { query: GET_ADMINS_QUERY }],
        }
    )

    return updateUserMutation
}

export const useRemoveAdminFromStudy = () => {
    const dispatch = useDispatch()
    const [removeAdminFromStudyMutation] = useMutation<
        removeAdminFromStudy,
        removeAdminFromStudyVariables
    >(REOMVE_ADMIN_FROM_STUDY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
        onCompleted: (res) =>
            dispatch<SetNotificationAction>(
                setNotificationAction({
                    severity: 'success',
                    text: `Administrator von Studie entfernt`,
                })
            ),
        refetchQueries: [{ query: GET_STUDIES_QUERY }],
    })

    return removeAdminFromStudyMutation
}

export const useAddAdminToStudy = () => {
    const dispatch = useDispatch()
    const [addAdminToStudyMutation] = useMutation<addAdminToStudy, addAdminToStudyVariables>(
        ADD_ADMIN_TO_STUDY,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
            onCompleted: (res) =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: `Administrator zur Studie hinzugefügt`,
                    })
                ),
            refetchQueries: [{ query: GET_STUDIES_QUERY }, { query: GET_ADMINS_QUERY }],
        }
    )

    return addAdminToStudyMutation
}
