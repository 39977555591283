import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
    mutation login($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            id
            username
            role
            hasRegisteredWithOwnPassword
            study {
                title
            }
        }
    }
`

export const LOGOUT_MUTATION = gql`
    mutation logout {
        logout
    }
`

export const CREATE_USER_MUTATION = gql`
    mutation createUser($username: String!, $role: UserRole!) {
        createUser(username: $username, role: $role) {
            id
            hasRegisteredWithOwnPassword
            username
            role
        }
    }
`

export const DELETE_USER_MUTATION = gql`
    mutation deleteUser($id: String!) {
        deleteUser(id: $id)
    }
`
export const REMOVE_PROBAND_FROM_STUDY = gql`
    mutation removeProbandFromStudy($probandId: String!) {
        removeProbandFromStudy(probandId: $probandId)
    }
`
export const UPDATE_PROBAND_STUDY = gql`
    mutation updateProbandStudy($probandId: String!, $newStudyId: String!) {
        updateProbandStudy(probandId: $probandId, newStudyId: $newStudyId)
    }
`
export const RESET_PASSWORD = gql`
    mutation resetPassword($userId: String!) {
        resetPassword(userId: $userId)
    }
`

export const REGISTER_PROBAND_MUTATION = gql`
    mutation registerProband($username: String!, $password: String!) {
        registerProband(username: $username, password: $password)
    }
`

export const DELETE_PHOTO_MUTATION = gql`
    mutation deletePhoto($fileName: String!, $onlyPhoto: Boolean!) {
        deletePhoto(fileName: $fileName, onlyPhoto: $onlyPhoto)
    }
`

export const DELETE_PHOTOS_FROM_DISK_MUTATION = gql`
    mutation deletePhotosFromDisk($fileNames: [String!]!) {
        deletePhotosFromDisk(fileNames: $fileNames)
    }
`

export const CREATE_FOOD_MUTATION = gql`
    mutation createFood($foodCreation: FoodCreation!) {
        createFood(foodCreation: $foodCreation) {
            id
            type
            description
            time
            picturePath
            createdDate
            foodBrands
        }
    }
`

export const DELETE_FOOD_MUTATION = gql`
    mutation deleteFood($id: String!) {
        deleteFood(id: $id) {
            id
        }
    }
`

export const UPDATE_FOOD_MUTATION = gql`
    mutation updateFood($id: String!, $update: FoodUpdate!) {
        updateFood(id: $id, update: $update) {
            id
            type
            description
            time
            picturePath
            createdDate
            foodBrands
        }
    }
`

export const UPDATE_USER_MUTATION = gql`
    mutation updateUser($id: String!, $update: UserUpdate!) {
        updateUser(id: $id, update: $update)
    }
`

export const ADD_TOKEN_MUTATION = gql`
    mutation addPushToken($token: String!) {
        addPushToken(token: $token)
    }
`

export const CREATE_STUDY_MUTATION = gql`
    mutation createStudy($title: String!) {
        createStudy(title: $title) {
            id
            title
        }
    }
`
export const DELETE_STUDY_MUTATION = gql`
    mutation deleteStudy($id: String!) {
        deleteStudy(id: $id)
    }
`

export const ADD_PROBAND_TO_STUDY = gql`
    mutation addProbandToStudy($probandId: String!, $studyId: String!) {
        addProbandToStudy(probandId: $probandId, studyId: $studyId) {
            id
            username
            study {
                id
                title
            }
        }
    }
`

export const SEND_PUSH_NOTIFICATION = gql`
    mutation sendPushNotification($text: String!) {
        sendPushNotification(text: $text)
    }
`

export const REOMVE_ADMIN_FROM_STUDY = gql`
    mutation removeAdminFromStudy($userId: String!, $studyId: String!) {
        removeAdminFromStudy(userId: $userId, studyId: $studyId) {
            id
            title
        }
    }
`

export const ADD_ADMIN_TO_STUDY = gql`
    mutation addAdminToStudy($userId: String!, $studyId: String!) {
        addAdminToStudy(userId: $userId, studyId: $studyId) {
            id
            title
        }
    }
`
