export type NotificationSeverity = 'info' | 'warning' | 'error' | 'success'

export interface NotificationType {
    showNotification: boolean
    message: string
    severity: NotificationSeverity
}

export type AppBarItem = 'saveNewFoodEntry' | 'editFoodEntry' | 'default'

export type Page = 'food-edit' | 'home' | 'food-edit-disabled' | 'food-new' | 'history'

export type Study = {
    title: string
    id: string
}
export type UiState = Readonly<{
    notification: NotificationType
    drawerTitle: string
    appBarItemClicked: AppBarItem | null
    location: {
        page: Page
    }
    isImageUploading: boolean
    isRtcPossible: boolean
}>

export const initialUiState: UiState = {
    notification: {
        message: '',
        severity: 'info',
        showNotification: false,
    },
    location: {
        page: 'home',
    },
    drawerTitle: 'Übersicht',
    appBarItemClicked: null,
    isImageUploading: false,
    isRtcPossible: true,
}
