import { useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SetErrorNotificationAction, setErrorNotificationAction } from '../../actions/ui'
import { getAvailableSpaceInMegaByte } from '../generated/getAvailableSpaceInMegaByte'
import { GET_AVAILABLE_SPACE } from '../queries'


export const useGetAvailableSpace = () => {
    const dispatch = useDispatch()
    const availableSpace = useQuery<getAvailableSpaceInMegaByte>(
        GET_AVAILABLE_SPACE,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(err?.message || 'Keine Daten gefunden!')
                ),
        }
    )

    return availableSpace
}
