import React, { ReactElement } from 'react'
import { Paper, Typography, Box } from '@material-ui/core'
import styled from 'styled-components'

interface Props {
    title: string
    children: any
}

 const CustomPaper = styled(Paper)`
        margin-bottom: 16px;
    `

export function HelpCard({ title, children }: Props): ReactElement {

    return (
        <CustomPaper>
            <Box p={2}>
                <Typography variant="h6">{title}</Typography>
                {children}
            </Box>
        </CustomPaper>
    )
}
