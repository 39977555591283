import { Fade, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Export from '@material-ui/icons/SaveAlt'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { AddProbandToStudyDialogContainer } from '../../../components/Dialog/AddProbandToStudy/AddProbandToStudyDialog.container'
import { ProbandDetailDialogContainer } from '../../../components/Dialog/ProbandDetailDialog/ProbandDetailDialog.container'
import {
    getProbandsWithFoods_getFoods,
    getProbandsWithFoods_getProbands,
    getProbandsWithFoods_getProbands_study,
} from '../../../graphql/generated/getProbandsWithFoods'
import { spacingLarge } from '../../../styles/spacing'
import { materialTableLocalization } from '../../../utils/de'
import icons from '../../../utils/table'

const TableWrapper = styled.div`
    margin-top: ${spacingLarge};
    max-width: 800px;
`

interface Props {
    addProbandToStudy: (probandId: string, studyId: string) => void
    exportCsv: (selectedProbandIds: string[]) => void
    foods: getProbandsWithFoods_getFoods[]
    isAdminUser: boolean
    loading: boolean
    probands: getProbandsWithFoods_getProbands[]
    studyTitle?: string
}

interface RowUser {
    username: string
    id: string
}

interface TableData {
    totalEntries: number
    selected: boolean
    id: string
    username: string
    hasRegisteredWithOwnPassword: boolean
    study: getProbandsWithFoods_getProbands_study | null
}

export const AttendingProbands: React.FC<Props> = ({
    probands,
    addProbandToStudy,
    foods,
    studyTitle,
    exportCsv,
    isAdminUser,
    loading,
}) => {
    const [showAddProbandToStudyDialog, setshowAddProbandToStudyDialog] = useState(false)
    const [probandDetailDialogData, setProbandDetailDialogData] = useState<RowUser | null>(null)
    const [tableData, settableData] = useState<TableData[]>([])

    const { id } = useParams<{ id: string | undefined }>()

    React.useEffect(() => {
        const probandsOfStudy = probands
            .filter((proband) => proband.study?.id === id)
            .map((proband) => ({
                ...proband,
                totalEntries: foods.filter((food) => food.userId === proband.id).length,
                selected: false,
            }))
        settableData(probandsOfStudy)
    }, [id, probands, foods])

    return (
        <div>
            <Typography style={{ marginTop: spacingLarge }} variant="h5">
                Teilnehmende Probanden
            </Typography>
            <Typography style={{ marginTop: spacingLarge }} variant="body1">
                Selektiere die gewünschten Probanden, um die Studiendaten zu exportieren.
            </Typography>
            <Fade in={!!id}>
                <TableWrapper>
                    <MaterialTable
                        isLoading={loading}
                        data={tableData}
                        title={`Studie ${studyTitle}`}
                        icons={icons as any}
                        localization={materialTableLocalization}
                        onRowClick={(_, rowData) =>
                            setProbandDetailDialogData(
                                rowData?.id ? { username: rowData.username, id: rowData.id } : null
                            )
                        }
                        actions={[
                            {
                                icon: Add,
                                tooltip: 'Probanden hinzufügen',
                                isFreeAction: true,
                                hidden: isAdminUser,
                                onClick: (event) => setshowAddProbandToStudyDialog(true),
                            },
                            {
                                icon: Export,
                                tooltip: 'Als CSV exportieren',
                                onClick: () => {
                                    const selectedProbandIds = tableData
                                        .filter((row) => row.selected === true)
                                        .map((proband) => proband.id)
                                    if (selectedProbandIds.length > 0) exportCsv(selectedProbandIds)
                                },
                            },
                        ]}
                        onSelectionChange={(rows) => {
                            settableData(
                                tableData.map((data) =>
                                    rows.map((_) => _.id).includes(data.id)
                                        ? { ...data, selected: true }
                                        : { ...data, selected: false }
                                )
                            )
                        }}
                        options={{
                            selection: true,
                            showTextRowsSelected: false,
                            selectionProps: (rowData: any) => ({
                                checked: rowData.selected,
                            }),
                        }}
                        columns={[
                            { title: 'Probandennummer', field: 'username' },
                            { title: 'Einträge', field: 'totalEntries' },
                        ]}
                    />
                </TableWrapper>
            </Fade>
            {showAddProbandToStudyDialog && (
                <AddProbandToStudyDialogContainer
                    open={showAddProbandToStudyDialog}
                    onDismiss={() => setshowAddProbandToStudyDialog(false)}
                    onConfirm={(probandId) => {
                        if (id) {
                            addProbandToStudy(probandId, id)
                            setshowAddProbandToStudyDialog(false)
                        }
                    }}
                />
            )}
            {probandDetailDialogData && (
                <ProbandDetailDialogContainer
                    onDismiss={() => setProbandDetailDialogData(null)}
                    probandId={probandDetailDialogData.id}
                    username={probandDetailDialogData.username}
                    open={!!probandDetailDialogData}
                />
            )}
        </div>
    )
}
