import React from 'react'
import { DailyFoodList } from './DailyFoodList'
import { useQuery } from '@apollo/react-hooks'
import { GET_ALL_MY_FOOD_QUERY } from '../../graphql/queries'
import { useDispatch } from 'react-redux'
import { setErrorNotificationAction, SetErrorNotificationAction } from '../../actions/ui'
import { getAllMyFood } from '../../graphql/generated/getAllMyFood'
import { useHistory } from 'react-router-dom'
import { millisStringToDate } from '../../utils/date'
import { isWithinInterval, startOfDay, endOfDay } from 'date-fns'

interface Props {
    day: Date
}

const DailyFoodListContainer: React.FC<Props> = ({ day }) => {
    const { data, error } = useQuery<getAllMyFood>(GET_ALL_MY_FOOD_QUERY)

    const history = useHistory()
    const dispatch = useDispatch()
    if (error || !data?.getAllMyFood) {
        if (error) {
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(error?.message || 'Keine Daten gefunden!')
            )
        }
        return <div />
    }

    const handleClick = (id: string) => {
        history.push(`/edit/${id}`)
    }

    const getFoodForSelectedDay = () =>
        data.getAllMyFood
            ?.filter((food) =>
                isWithinInterval(millisStringToDate(food.time), {
                    start: startOfDay(day),
                    end: endOfDay(day),
                })
            )
            .sort((a, b) => (a.time > b.time ? 1 : -1)) || []

    return <DailyFoodList handleClick={handleClick} food={getFoodForSelectedDay()} />
}

export default DailyFoodListContainer
