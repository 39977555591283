import { Button, Link, Typography } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import React from 'react'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import { spacingLarge, spacingMedium } from '../../styles/spacing'
import { RootsTableContainer } from './RootsTable/RootsTable.container'

export const RootsScreen: React.FC = () => {
    useDrawerTitle('Roots')
    return (
        <div>
            <Typography style={{ marginBottom: spacingMedium }} variant="h4">
                Alle Roots
            </Typography>

            <RootsTableContainer />

            <Typography style={{ marginTop: spacingLarge }} variant="h5">
                Log Dateien
            </Typography>
            <Link href={`${process.env.REACT_APP_BACKEND_BASE_URL}/log`}>
                <Button
                    style={{ marginTop: spacingMedium }}
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                >
                    Log-Datei herunterladen
                </Button>
            </Link>
        </div>
    )
}
