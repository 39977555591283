import React from 'react'
import { useGetAdmins, useAddAdminToStudy } from '../../../graphql/hooks/user'
import { AddAdminToStudyDialog } from './AddAdminToStudyDIalog'

interface Props {
    open: boolean
    onDismiss: () => void
    onConfirm: () => void
    studyId: string
}

export const AddAdminToStudyDialogContainer: React.FC<Props> = ({
    open,
    onConfirm,
    onDismiss,
    studyId,
}) => {
    const { data } = useGetAdmins()

    const addAdminToStudy = useAddAdminToStudy()

    const handleAddAmin = (adminId: string, studyId: string) => {
        addAdminToStudy({
            variables: {
                studyId,
                userId: adminId,
            },
        }).then(onConfirm)
    }

    return (
        <AddAdminToStudyDialog
            onConfirm={handleAddAmin}
            admins={data?.getAdmins || []}
            open={open}
            onDismiss={onDismiss}
            studyId={studyId}
        />
    )
}
