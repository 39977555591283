import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import TakePhoto from '@material-ui/icons/CameraOutlined'

interface CameraProps {
    show: boolean
}

export const Camera = styled.div<CameraProps>`
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
    z-index: 1101;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: ${(props) => (props.show ? 'flex' : 'none')};
`
export const Canvas = styled.canvas`
    display: none;
`

export const TriggerButton = styled(IconButton)`
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 1101;
    color: white;
`

export const PhotoIcon = styled(TakePhoto)`
    font-size: 4rem;
`
