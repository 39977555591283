/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum FoodType {
  drink = "drink",
  meal = "meal",
  snack = "snack",
}

export enum UserRole {
  admin = "admin",
  root = "root",
  student = "student",
}

export interface FoodCreation {
  type: FoodType;
  description: string;
  picturePath?: string | null;
  foodBrands?: string | null;
  time: string;
  userId: string;
}

export interface FoodUpdate {
  description: string;
  picturePath?: string | null;
  foodBrands?: string | null;
  time: string;
  type: FoodType;
}

export interface UserUpdate {
  username: string;
  role: UserRole;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
