import * as React from 'react'
import App from './App'
import { useQuery } from '@apollo/react-hooks'
import { ME_QUERY } from './graphql/queries'
import { useDispatch } from 'react-redux'
import { SetNotificationAction, setNotificationAction } from './actions/ui'
import {
    ReceiveCurrentUserAction,
    receiveCurrentUserAction,
    LogoutCurrentUserAction,
    logoutCurrentUser,
} from './actions/data'
import { me } from './graphql/generated/me'
import { getMessagingInstance } from './firebase'
import { useAddTokenMutation } from './graphql/hooks/pushNotification'

export const AppContainer: React.FC = () => {
    const { data, error, loading } = useQuery<me>(ME_QUERY)
    const addPushTokenMutation = useAddTokenMutation()

    React.useEffect(() => {
        try {
            const messaging = getMessagingInstance()
            messaging
                .getToken()
                .then((currentToken) => {
                    if (currentToken) {
                        addPushTokenMutation({
                            variables: {
                                token: currentToken,
                            },
                        }).catch((err) =>
                            console.log('Token could not be added to user:', err.message)
                        )
                    } else {
                        // Show permission request.
                        console.log(
                            'No Instance ID token available. Request permission to generate one.'
                        )
                    }
                })
                .catch((err) => {
                    console.log('An error occurred while retrieving token. ', err)
                })
            // Callback fired if Instance ID token is updated.
            messaging.onTokenRefresh(() => {
                messaging
                    .getToken()
                    .then((refreshedToken) => {
                        console.log('Token refreshed.')
                        addPushTokenMutation({
                            variables: {
                                token: refreshedToken,
                            },
                        }).catch((err) =>
                            console.log('Token could not be added to user:', err.message)
                        )
                    })
                    .catch((err) => {
                        console.log('Unable to retrieve refreshed token ', err)
                    })
            })

            messaging.onMessage((payload) => console.log('Message Received', payload))
        } catch (error) {
            console.log(error)
        }
    }, [addPushTokenMutation])

    const dispatch = useDispatch()

    if (error) {
        dispatch<SetNotificationAction>(
            setNotificationAction({ severity: 'error', text: error.message })
        )
    }

    if (!data?.me && !loading) {
        dispatch<LogoutCurrentUserAction>(logoutCurrentUser())
    }

    if (data?.me) {
        dispatch<ReceiveCurrentUserAction>(receiveCurrentUserAction(data.me))
    }

    if (!loading) {
        return <App />
    }

    return <div />
}
