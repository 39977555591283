import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import HistoryIcon from '@material-ui/icons/History'
import AdminIcon from '@material-ui/icons/TableChart'
import AddIcon from '@material-ui/icons/AddCircle'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import HelpIcon from '@material-ui/icons/Help'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import MenuIcon from '@material-ui/icons/Menu'
import Logout from '@material-ui/icons/ExitToApp'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import { Box, Button, Menu, MenuItem, Collapse } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { AppBarItem, Page } from '../../state/ui-state'
import { pageToAppBarAction } from '../../utils/common'
import { me_me } from '../../graphql/generated/me'
import ExpandMoreIcon from '@material-ui/icons/MoreVert'
import { format } from 'date-fns/esm'
import { UserRole } from '../../graphql/generated/globalTypes'

const drawerWidth = 240

export type Pages =
    | 'login'
    | 'dashboard'
    | 'new'
    | 'history'
    | 'help'
    | 'backoffice/studies'
    | 'backoffice/probands'
    | 'backoffice/admins'
    | 'backoffice/roots'
    | 'backoffice/pushNotifications'
    | 'backoffice/space'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        title: {
            flexGrow: 1,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
        },
    })
)

interface Props {
    title: string
    setTitle: (title: string) => void
    handleAppBarItemClick: (item: AppBarItem) => void
    page: Page
    user: me_me | null
    logout: () => void
}

export const DrawerLayout: React.FC<Props> = ({
    children,
    title,
    handleAppBarItemClick,
    page,
    user,
    logout,
}) => {
    const classes = useStyles()
    const theme = useTheme()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null)
    const open = Boolean(anchorEl)
    const [expandAdmin, setexpandAdmin] = useState(false)

    const history = useHistory()
    const location = useLocation()

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleNavigation = (page: Pages) => {
        //TODO make title depending on Location EI-37
        if (page === 'history') {
            history.push(`/history/${format(new Date(), 'yyyy-MM-dd')}`)
        } else {
            history.push(`/${page}`)
        }
        setMobileOpen(false)
    }

    const handleMoreIconClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const isAdmin = user?.role === UserRole.admin

    const isRoot = user?.role === UserRole.root

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Box marginLeft={2} marginBottom={1} marginTop={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">{user?.username}</Typography>
                    <IconButton onClick={handleMoreIconClick}>
                        <ExpandMoreIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
                        <MenuItem onClick={logout}>
                            <Logout style={{ marginRight: '8px' }} />
                            Abmelden
                        </MenuItem>
                    </Menu>
                </Box>
                {user?.study && user?.role === UserRole.student && (
                    <Typography style={{ marginTop: -12, color: '#708090' }} variant="subtitle1">
                        {user.study.title}
                    </Typography>
                )}
                {(isAdmin || isRoot) && (
                    <Typography style={{ marginTop: -12, color: '#708090' }} variant="subtitle1">
                        {isAdmin ? 'Administrator' : 'Root'}
                    </Typography>
                )}
            </Box>
            <Divider />
            <List>
                <ListItem button onClick={() => handleNavigation('dashboard')}>
                    <ListItemIcon>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Übersicht'} />
                </ListItem>
                <ListItem button onClick={() => handleNavigation('new')}>
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Neuer Eintrag'} />
                </ListItem>
                <ListItem button onClick={() => handleNavigation('history')}>
                    <ListItemIcon>
                        <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Historie'} />
                </ListItem>
                {(isAdmin || isRoot) && (
                    <>
                        <ListItem button onClick={() => setexpandAdmin(!expandAdmin)}>
                            <ListItemIcon>
                                <AdminIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Verwaltung'} />
                            {expandAdmin ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={expandAdmin} timeout="auto" unmountOnExit>
                            <List component="div" style={{ paddingLeft: '70px' }} disablePadding>
                                {isAdmin && (
                                    <>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/studies')}
                                        >
                                            <ListItemText primary="Studien" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/probands')}
                                        >
                                            <ListItemText primary="Probanden" />
                                        </ListItem>
                                    </>
                                )}
                                {isRoot && (
                                    <>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/studies')}
                                        >
                                            <ListItemText primary="Studien" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/probands')}
                                        >
                                            <ListItemText primary="Probanden" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/admins')}
                                        >
                                            <ListItemText primary="Administratoren" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/roots')}
                                        >
                                            <ListItemText primary="Roots" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() =>
                                                handleNavigation('backoffice/pushNotifications')
                                            }
                                        >
                                            <ListItemText primary="Benachrichtigungen" />
                                        </ListItem>
                                        <ListItem
                                            button
                                            onClick={() => handleNavigation('backoffice/space')}
                                        >
                                            <ListItemText primary="Speicher" />
                                        </ListItem>
                                    </>
                                )}
                            </List>
                        </Collapse>
                    </>
                )}
                <ListItem button onClick={() => handleNavigation('help')}>
                    <ListItemIcon>
                        <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Hilfe'} />
                </ListItem>
            </List>
        </div>
    )

    const drawerButton = () => {
        return (
            (location.pathname === '/new' || page === 'food-edit') && (
                <Button
                    onClick={() => handleAppBarItemClick(pageToAppBarAction(page))}
                    color="inherit"
                >
                    Speichern
                </Button>
            )
        )
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Divider />
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title} noWrap>
                        {title}
                    </Typography>
                    {drawerButton()}
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    )
}
