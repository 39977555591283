import { ExportToCsv, Options } from 'export-to-csv'

export type CSVHeader = string[]

export const export2csv = (title: string, data: unknown[], filename: string) => {
    const options: Options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: title,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename,
    }

    const csvExporter = new ExportToCsv(options)
    if (data.length > 0) csvExporter.generateCsv(data)
}

export const replaceSemicolonWithComma = (text: string) => text.replaceAll(';', ',')
