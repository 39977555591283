import React from 'react'
import { EditFoodScreen } from './EditFoodScreen'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../state/app-state'
import { AppBarItem } from '../../state/ui-state'
import {
    DismissAppBarClicks,
    SetErrorNotificationAction,
    setErrorNotificationAction,
    SetDrawerTitleAction,
    SetPageAction,
    setPageAction,
    SetNotificationAction,
    setNotificationAction,
} from '../../actions/ui'
import { useQuery } from '@apollo/react-hooks'
import { GET_FOOD_QUERY } from '../../graphql/queries'
import { getFood, getFoodVariables } from '../../graphql/generated/getFood'
import { useParams, useHistory } from 'react-router-dom'
import { isTodayOrYesterday } from '../../utils/date'
import { usePage } from '../../hooks/usePage'
import { useDeleteFood, useGetAllMyFood, useUpdateFood } from '../../graphql/hooks/food'
import { updateFoodVariables } from '../../graphql/generated/updateFood'

export const EditFoodScreenContainer: React.FC = () => {
    const dispatch = useDispatch()

    usePage('food-edit-disabled')

    React.useEffect(() => {
        dispatch<SetDrawerTitleAction>(SetDrawerTitleAction('Eintrag bearbeiten'))
    }, [dispatch])

    const { id } = useParams<{ id: string }>()

    const history = useHistory()

    const [deleteFoodHook] = useDeleteFood()
    const [updateFoodHook] = useUpdateFood()
    const myFood = useGetAllMyFood()

    const { loading, data } = useQuery<getFood, getFoodVariables>(GET_FOOD_QUERY, {
        variables: {
            id: id || '',
        },
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message)),
        onCompleted: (res) =>
            isTodayOrYesterday(res.getFood.time) &&
            dispatch<SetPageAction>(setPageAction('food-edit')),
    })

    const clickedMenuItems = useSelector<AppState, AppBarItem | null>(
        (store) => store.ui.appBarItemClicked
    )

    const handleDeleteFood = async (foodId: string) => {
        try {
            await deleteFoodHook({
                variables: {
                    id: foodId,
                },
            })
            dispatch<SetNotificationAction>(
                setNotificationAction({ severity: 'success', text: 'Eintrag gelöscht' })
            )
            history.push('/')
        } catch (error: any) {
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(`Fehler beim Löschen des Eintrages: ${error.message}`)
            )
        }
    }

    const handleUpdateFood = async (update: updateFoodVariables) => {
        try {
            await updateFoodHook({
                variables: {
                    id: update.id,
                    update: update.update,
                },
            })
            dispatch<SetNotificationAction>(
                setNotificationAction({ severity: 'success', text: 'Eintrag aktualisiert' })
            )
            history.goBack()
        } catch (error: any) {
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(
                    `Fehler beim Aktualisieren des Eintrages: ${error.message}`
                )
            )
        }
    }

    const resetAppBarClicks = () => dispatch<DismissAppBarClicks>(DismissAppBarClicks())

    if (loading || !data) return <div />

    return (
        <EditFoodScreen
            historicFood={myFood?.getAllMyFood || []}
            deleteFood={handleDeleteFood}
            food={data.getFood}
            isEditable={isTodayOrYesterday(data.getFood.time)}
            resetSaveButton={resetAppBarClicks}
            updateFood={handleUpdateFood}
            clickedMenuItems={clickedMenuItems}
        />
    )
}
