import { Typography } from '@material-ui/core'
import React from 'react'
import { spacingMedium } from '../../styles/spacing'
import { AdminsTableContainer } from './AdminsTable/AdminsTable.container'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'

export const AdminsScreen: React.FC = () => {
    useDrawerTitle('Adminstratoren')
    return (
        <div>
            <Typography style={{ marginBottom: spacingMedium }} variant="h4">
                Alle Administratoren
            </Typography>

            <AdminsTableContainer />
        </div>
    )
}
