import { me_me as User } from '../graphql/generated/me'

export type DataAtion = ReceiveCurrentUserAction | LogoutCurrentUserAction

export enum ActionType {
    RECEIVE_CURRENT_USER = 'receive_current_user',
    LOGOUT_CURRENT_USER = 'logout-current-user',
}

export type ReceiveCurrentUserAction = Readonly<{
    type: ActionType.RECEIVE_CURRENT_USER
    user: User
}>

export type LogoutCurrentUserAction = Readonly<{
    type: ActionType.LOGOUT_CURRENT_USER
}>

export const receiveCurrentUserAction = (user: User): ReceiveCurrentUserAction => ({
    type: ActionType.RECEIVE_CURRENT_USER,
    user,
})

export const logoutCurrentUser = (): LogoutCurrentUserAction => ({
    type: ActionType.LOGOUT_CURRENT_USER,
})
