import { format, isToday, isYesterday, isWithinInterval, startOfDay, endOfDay } from 'date-fns'

export const millisToFormatString = (millis: string, formatText: string) => {
    return format(new Date(parseInt(millis, 10)), formatText)
}

/**
 *
 * @param millis Time in Millis
 */
export const isTodayOrYesterday = (millis: string) => {
    const date = new Date(parseInt(millis, 10))
    return isToday(date) || isYesterday(date)
}

export const millisStringToDate = (millis: string) => {
    return new Date(parseInt(millis, 10))
}

export const isBetweenStartAndEndOfDay = (dateToCheck: Date, date: Date) =>
    isWithinInterval(dateToCheck, {
        start: startOfDay(date),
        end: endOfDay(date),
    })
