import * as React from 'react'
import { useTheme, makeStyles, Paper, Typography, Chip, Box } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { subMinutes, isToday, isYesterday, subDays } from 'date-fns'

export type Props = Readonly<{
    onDateChange: (date: Date) => void
    value: Date
    withChips?: boolean
    disabled?: boolean
}>

type Chip = 'now' | '10min' | '30min' | '1h' | '2h'

export const DateChoice: React.FC<Props> = ({
    onDateChange,
    value,
    withChips = true,
    disabled = false,
}) => {
    const theme = useTheme()
    const [selectedChip, setChip] = React.useState<Chip | null>('now')

    const styles = makeStyles({
        typ: {
            padding: 2 * theme.spacing(),
            paddingTop: 1 * theme.spacing(),
            marginTop: 2 * theme.spacing(),
        },
        heading: {
            marginBottom: theme.spacing(),
        },
        chips: {
            margin: 0.5 * theme.spacing(),
        },
        dateInput: {
            marginTop: theme.spacing(),
        },
    })

    const classes = styles()

    const handleChipSelection = (minutesToSubtract: number, chipTyp: Chip) => {
        onDateChange(subMinutes(new Date(), minutesToSubtract))
        setChip(chipTyp)
    }

    const getChipColor = (chip: Chip) => {
        if (chip === selectedChip) return 'primary'
        return 'default'
    }
    return (
        <Paper className={classes.typ}>
            <Typography className={classes.heading} variant="h6">
                Uhrzeit
            </Typography>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
                {withChips && (
                    <>
                        <Chip
                            color={getChipColor('now')}
                            className={classes.chips}
                            onClick={() => handleChipSelection(0, 'now')}
                            label="Jetzt"
                        />
                        <Chip
                            color={getChipColor('10min')}
                            className={classes.chips}
                            onClick={() => handleChipSelection(10, '10min')}
                            label="vor 10 min"
                        />
                        <Chip
                            color={getChipColor('30min')}
                            className={classes.chips}
                            onClick={() => handleChipSelection(30, '30min')}
                            label="vor 30 min"
                        />
                        <Chip
                            color={getChipColor('1h')}
                            className={classes.chips}
                            onClick={() => handleChipSelection(60, '1h')}
                            label="vor 1h"
                        />
                        <Chip
                            color={getChipColor('2h')}
                            className={classes.chips}
                            onClick={() => handleChipSelection(120, '2h')}
                            label="vor 2h"
                        />
                    </>
                )}

                <DateTimePicker
                    inputVariant="outlined"
                    value={value}
                    fullWidth
                    cancelLabel="Abbrechen"
                    disableFuture
                    disabled={disabled}
                    className={classes.dateInput}
                    onAccept={() => setChip(null)}
                    onChange={(evt) => evt && onDateChange(new Date(evt))}
                    ampm={false}
                    shouldDisableDate={(day) =>
                        !(
                            isToday(new Date(day!.toDateString())) ||
                            isYesterday(new Date(day!.toDateString()))
                        )
                    }
                    minDate={disabled ? new Date('1900 - 01 - 01') : subDays(new Date(), 2)}
                />
            </Box>
        </Paper>
    )
}
