import React from 'react'
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
} from '@material-ui/core'
import { millisToFormatString } from '../../utils/date'
import { FoodTypeConverter } from '../../utils/common'
import PhotoIcon from '@material-ui/icons/Photo'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { getAllMyFood_getAllMyFood } from '../../graphql/generated/getAllMyFood'
interface Props {
    item: getAllMyFood_getAllMyFood
    onClick: (id: string) => void
    showDivider?: boolean
}

export const FoodListItem: React.FC<Props> = ({ item, onClick, showDivider = true }) => {
    const [showImageIcon, setshowImageIcon] = React.useState(false)
    return (
        <>
            <ListItem onClick={() => onClick(item?.id || '')} button key={item?.id}>
                <ListItemAvatar style={{ display: 'flex' }}>
                    {item.picturePath && !showImageIcon ? (
                        <img
                            alt="thumbnail"
                            src={`${process.env.REACT_APP_THUMBNAIL_URL}/?src=/${item.picturePath}&w=50&h=50`}
                            height="30"
                            onError={() => setshowImageIcon(true)}
                        />
                    ) : (
                        <PhotoIcon />
                    )}
                </ListItemAvatar>
                <ListItemText
                    secondary={`${millisToFormatString(item.time, 'HH:mm')} - ${FoodTypeConverter(
                        item.type
                    )}`}
                    primary={item?.description}
                />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete">
                        <ArrowRightIcon />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
            {showDivider && <Divider component="li" />}
        </>
    )
}
