import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { LOGIN_MUTATION } from '../../graphql/mutations'
import { Login } from './Login'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
    SetNotificationAction,
    setNotificationAction,
    SetErrorNotificationAction,
    setErrorNotificationAction,
} from '../../actions/ui'
import { ReceiveCurrentUserAction, receiveCurrentUserAction } from '../../actions/data'
import { loginVariables, login } from '../../graphql/generated/login'
import { useRegisterMutation } from '../../graphql/hooks/user'
import { useState } from 'react'

export const LoginContainer: React.FC = () => {
    const [loginMutation] = useMutation<login, loginVariables>(LOGIN_MUTATION)
    const registerProband = useRegisterMutation()
    const [hasSuccessfullyRegistered, sethasSuccessfullyRegistered] = useState(false)

    const dispatch = useDispatch()

    const history = useHistory()

    const handleLogin = (username: string, password: string) => {
        loginMutation({
            variables: {
                password,
                username,
            },
        })
            .then((res) => {
                if (res.data?.login) {
                    dispatch<ReceiveCurrentUserAction>(receiveCurrentUserAction(res.data?.login))
                    history.push('/dashboard')
                }
            })
            .catch((err: any) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message))
            )
    }

    const handleRegisterProband = (username: string, password: string) => {
        return registerProband({
            variables: {
                username,
                password,
            },
        })
            .then((_) => {
                dispatch<SetNotificationAction>(
                    setNotificationAction({ text: 'Erfolgreich registiert!', severity: 'success' })
                )
                sethasSuccessfullyRegistered(true)
            })
            .catch((err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message))
            )
    }
    return (
        <Login
            hasSuccessfullyRegistered={hasSuccessfullyRegistered}
            handleRegisterProband={handleRegisterProband}
            handleLogin={handleLogin}
        />
    )
}
