import React from 'react'
import Alert from '@material-ui/lab/Alert'
import { Snackbar } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../state/app-state'
import { NotificationType } from '../../state/ui-state'
import { DismissNotificationAction, dismissNotificationAction } from '../../actions/ui'

export const Notification = () => {
    const notification = useSelector<AppState, NotificationType>((store) => store.ui.notification)

    const dispatch = useDispatch()

    return (
        <Snackbar
            autoHideDuration={4000}
            open={notification.showNotification}
            onClose={() => {
                dispatch<DismissNotificationAction>(dismissNotificationAction())
            }}
        >
            <Alert severity={notification.severity}>{notification.message}</Alert>
        </Snackbar>
    )
}
