import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    DismissAppBarClicks,
    SetErrorNotificationAction,
    setErrorNotificationAction,
    SetNotificationAction,
    setNotificationAction,
} from '../../actions/ui'
import { FoodCreation } from '../../graphql/generated/globalTypes'
import { useCreateFood, useGetAllMyFood } from '../../graphql/hooks/food'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import { usePage } from '../../hooks/usePage'
import { AppState } from '../../state/app-state'
import { AppBarItem } from '../../state/ui-state'
import { CreateFoodScreen } from './CreateFoodScreen'

const CreateFoodScreenContainer = () => {
    usePage('food-new')
    useDrawerTitle('Eintrag erstellen')

    const clickedMenuItems = useSelector<AppState, AppBarItem | null>(
        (store) => store.ui.appBarItemClicked
    )

    const myFood = useGetAllMyFood()

    const dispatch = useDispatch()

    const [createFoodMutation] = useCreateFood()

    const history = useHistory()

    const resetAppBarClicks = () => dispatch<DismissAppBarClicks>(DismissAppBarClicks())

    const createFoodEntry = (foodCreation: FoodCreation) => {
        createFoodMutation({
            variables: {
                foodCreation,
            },
        })
            .then(() => {
                history.push('/dashboard')
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        text: 'Eintrag erfolgreich hinzugefügt.',
                        severity: 'success',
                    })
                )
            })

            .catch((err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message))
            )
    }

    return (
        <CreateFoodScreen
            createFood={createFoodEntry}
            resetSaveButton={resetAppBarClicks}
            clickedMenuItems={clickedMenuItems}
            myFood={myFood?.getAllMyFood || []}
        />
    )
}

export default CreateFoodScreenContainer
