import * as React from 'react'
import { Button } from '@material-ui/core'
import { useState } from 'react'
import { Camera, TriggerButton, PhotoIcon, Canvas } from './PhotoStream.styles'

export type StreamNotPossibleReason =
    | 'noPermissionGranted'
    | 'missingDeviceSupport'
    | 'notMeetingContraintRestrictions'

export type Props = Readonly<{
    onStreamNotPossible: (reason: StreamNotPossibleReason) => void
    onStreamIsPossible: () => void
    onImageCaptured: (blob: Blob, dataUrl: string) => void
}>

export const rtcConstraints = {
    video: {
        width: {
            min: 1280,
            ideal: 1920,
            max: 2560,
        },
        height: {
            min: 720,
            ideal: 1080,
            max: 1440,
        },
        facingMode: {
            exact: 'environment',
        },
    },
    audio: false,
}

export const PhotoStream: React.FC<Props> = ({
    onStreamNotPossible,
    onStreamIsPossible,
    onImageCaptured,
}) => {
    React.useEffect(() => {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            navigator.mediaDevices
                .getUserMedia(rtcConstraints)
                .then((stream) => {
                    
                    if (videoRef.current) {
                        videoRef.current.srcObject = stream
                        videoRef.current.play().then(_ => onStreamIsPossible())

                    }
                })
                .catch((err) => {
                    if (err instanceof OverconstrainedError) {
                        onStreamNotPossible('notMeetingContraintRestrictions')
                        return
                    }
                    console.error('An error occurred: ' + err)
                    onStreamNotPossible('noPermissionGranted')
                })

            clearPhoto()
        } else {
            onStreamNotPossible('missingDeviceSupport')
        }
        return function cleanUp() {
            stopStreamedVideo()
        }
    }, [onStreamIsPossible, onStreamNotPossible])

    const [streaming, setStreaming] = useState(false)
    const [height, setHeight] = useState(0)
    const [width] = useState(320)

    const videoRef = React.useRef<HTMLVideoElement>(null)
    const canvasRef = React.useRef<HTMLCanvasElement>(null)

    const handleVideoCanPlay = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        if (!streaming && videoRef.current && canvasRef.current) {
            setHeight(videoRef.current.videoHeight / (videoRef.current.videoWidth / width))
            setStreaming(true)
        }
    }

    const handleTakePhotoClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        takepicture()
        stopStreamedVideo()
    }

    const stopStreamedVideo = () => {
        if (videoRef.current) {
            const stream = videoRef.current?.srcObject
            if (stream instanceof MediaStream) {
                const tracks = stream?.getVideoTracks()

                tracks?.forEach((track) => track.stop())
                videoRef.current.srcObject = null
            }
            setStreaming(false)
        }
    }

    const takepicture = () => {
        const canvas = canvasRef.current

        if (canvas) {
            const context = canvas.getContext('2d')
            if (width && height && context && videoRef.current) {
                canvas.width = videoRef.current.videoWidth
                canvas.height = videoRef.current.videoHeight
                context.drawImage(videoRef.current, 0, 0)

                const data = canvas.toDataURL('image/webp')
                canvas.toBlob((blob) => blob && onImageCaptured(blob, data))
                stopStreamedVideo()
            } else {
                clearPhoto()
            }
        }
    }

    const clearPhoto = () => {
        const canvas = canvasRef.current
        if (canvas) {
            const context = canvas.getContext('2d')
            if (context) {
                context.fillStyle = '#AAA'
                context.fillRect(0, 0, canvas.width, canvas.height)
            }
        }
    }

    return (
        <>
            <Camera show={streaming}>
                <video height={height} width={width} onCanPlay={handleVideoCanPlay} ref={videoRef}>
                    Stream not available
                </video>
                <TriggerButton onClick={handleTakePhotoClick}>
                    <PhotoIcon fontSize="large" />
                </TriggerButton>
                <Button onClick={stopStreamedVideo}>Stop Stream</Button>
            </Camera>
            <Canvas height={height} width={width} ref={canvasRef} />
        </>
    )
}
