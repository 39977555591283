import styled from 'styled-components'
import { Button } from '@material-ui/core'
import Warning from '@material-ui/icons/WarningRounded'

export const DeleteButton = styled(Button)`
    margin-top: 16px;
`
export const ReadOnlyBanner = styled.div`
    width: 100%;
    background-color: #fff4e5;
    display: flex;
    padding: 6px 16px;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`

export const WarningIcon = styled(Warning)`
    margin-right: 8px;
    color: darkorange;
`
