import * as React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
    Select,
    InputLabel,
} from '@material-ui/core'
import { useState } from 'react'
import { UserRole } from '../../graphql/generated/globalTypes'
import { UserRoleConverter } from '../../utils/common'
import { Study } from '../../state/ui-state'
import { spacingMedium, spacingSmall } from '../../styles/spacing'

export type Props = Readonly<{
    open: boolean
    onDismiss: () => void
    onConfirm: (username: string, assignedStudyId: string | null) => void
    role: UserRole
    assignableStudies?: Study[]
    placeholder?: string
}>

export const CreateUserDialog: React.FC<Props> = ({
    open,
    assignableStudies,
    onDismiss,
    onConfirm,
    role,
    placeholder,
}) => {
    const [username, setUsername] = useState('')
    const [selectedStudyId, setselectedStudyId] = useState<string | null>(null)

    const hasAssignableStudies = assignableStudies !== undefined

    React.useEffect(() => {
        setUsername('')
    }, [open])

    React.useEffect(() => {
        if (assignableStudies && assignableStudies.length > 0) {
            setselectedStudyId(assignableStudies[0].id)
        }
    }, [assignableStudies])

    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{UserRoleConverter(role)} erstellen</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    label="Probandennummer"
                    onChange={(evt) => setUsername(evt.currentTarget.value)}
                    value={username}
                    style={{ width: '100%' }}
                    variant="outlined"
                    placeholder={placeholder || 'Probandennummer'}
                />
                {assignableStudies !== undefined && (
                    <>
                        <InputLabel style={{ marginTop: spacingMedium }} id="label-select-study">
                            Zugewiesene Studie
                        </InputLabel>
                        <Select
                            variant="outlined"
                            labelId="label-select-study"
                            placeholder="Weise eine Studie zu"
                            style={{
                                width: '100%',
                                marginBottom: spacingMedium,
                                marginTop: spacingSmall,
                            }}
                            onChange={(evt) => setselectedStudyId(evt.target.value as string)}
                            value={selectedStudyId}
                        >
                            {assignableStudies.map((study) => (
                                <MenuItem value={study.id}>{study.title}</MenuItem>
                            ))}
                        </Select>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss}>Abbrechen</Button>
                <Button
                    disabled={username === '' || (hasAssignableStudies && selectedStudyId === null)}
                    onClick={() => onConfirm(username, selectedStudyId)}
                    color="primary"
                    autoFocus
                >
                    erstellen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
