import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import deLocale from 'date-fns/locale/de'
import * as React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Prompt, useLocation } from 'react-router-dom'
import { SetNotificationAction, setNotificationAction } from '../../actions/ui'
import { AddPhotoContainer } from '../../components/CreateFood/AddPhoto/AddPhoto.container'
import { DateChoice } from '../../components/CreateFood/DateChoice'
import { Description } from '../../components/CreateFood/Description'
import { FoodBrands } from '../../components/CreateFood/FoodBrands'
import { TypeChoice } from '../../components/CreateFood/TypChoice'
import { FoodCreation, FoodType } from '../../graphql/generated/globalTypes'
import { me_me } from '../../graphql/generated/me'
import { Food } from '../../models'
import { AppState } from '../../state/app-state'
import { AppBarItem } from '../../state/ui-state'
import {
    filterValuesForAutocompleteDescription,
    filterValuesForAutocompleteFoodBrands,
} from './utils/autocomplete'

interface Props {
    clickedMenuItems: AppBarItem | null
    resetSaveButton: () => void
    createFood: (foodCreation: FoodCreation) => void
    myFood: Food[]
}

interface InputError {
    description: string | null
    date: string | null
}

// TODO merge with EditFoodScreen

export const CreateFoodScreen: React.FC<Props> = ({
    clickedMenuItems,
    resetSaveButton,
    createFood,
    myFood,
}) => {
    const isImageUploading = useSelector<AppState, boolean>((store) => store.ui.isImageUploading)
    const user = useSelector<AppState, me_me | null>((store) => store.data.user)
    const dispatch = useDispatch()

    React.useEffect(() => {
        if (clickedMenuItems === 'saveNewFoodEntry') {
            setsavePressed(true)
            if (description.length === 0) {
                setInputError({ ...inputError, description: 'Keine Beschreibung angegeben!' })
            } else if (isImageUploading) {
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'warning',
                        text:
                            'Eintrag konnte nicht gespeichert werden, da der Bildupload noch nicht fertiggestellt wurde!',
                    })
                )
            } else {
                if (user) {
                    createFood({
                        description,
                        foodBrands: foodBrands === '' ? null : foodBrands,
                        picturePath: imageFilename,
                        time: date.toISOString(),
                        type,
                        userId: user.id,
                    })
                }
            }
            resetSaveButton()
        }
    }, [clickedMenuItems, resetSaveButton, isImageUploading])

    const getDefaultValue = () => {
        if (location.state) {
            return location.state as FoodType
        }
        return FoodType.meal
    }
    const location = useLocation()

    const [type, setType] = useState<FoodType>(getDefaultValue())
    const [date, setDate] = useState<Date>(new Date())
    const [description, setDescription] = useState<string>('')
    const [foodBrands, setFoodBrands] = useState<string>('')
    const [imageFilename, setImageFile] = useState<string | null>(null)
    const [inputError, setInputError] = useState<InputError>({ date: null, description: null })
    const [savePressed, setsavePressed] = useState(false)

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <>
                <Prompt
                    when={(!!imageFilename || description.length > 0) && !savePressed}
                    message="Ungespeicherte Änderungen verwerfen?"
                />
                <TypeChoice value={type} onTypeChange={(type) => setType(type)} />
                <DateChoice value={date} onDateChange={(date) => setDate(date)} />
                <Description
                    valuesForAutocomplete={filterValuesForAutocompleteDescription(myFood, type)}
                    errorMessage={inputError.description}
                    value={description}
                    disabled={false}
                    onDescriptionChange={(description) => {
                        setDescription(description)
                        setInputError({ ...inputError, description: null })
                    }}
                />
                <FoodBrands
                    valuesForAutocomplete={filterValuesForAutocompleteFoodBrands(myFood, type)}
                    value={foodBrands}
                    onFoodBrandsChange={setFoodBrands}
                />
                <AddPhotoContainer
                    isNewEntry
                    filename={imageFilename}
                    initialImageSource={null}
                    onImageChange={(filename) => setImageFile(filename)}
                />
            </>
        </MuiPickersUtilsProvider>
    )
}
