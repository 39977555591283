import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { SetDrawerTitleAction } from '../actions/ui'

export const useDrawerTitle = (title: string) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch<SetDrawerTitleAction>(SetDrawerTitleAction(title))
    }, [dispatch, title])
}
