import * as firebase from 'firebase/app'
import 'firebase/firebase-messaging'

// TODO add env variables
export const firebaseConfig = {
    apiKey: "AIzaSyBKBVigXZNe6JucVPrM0Vjbm69bDe6yTY0",
    authDomain: "mealtracking-production.firebaseapp.com",
    databaseURL: "https://mealtracking-production.firebaseio.com",
    projectId: "mealtracking-production",
    storageBucket: "mealtracking-production.appspot.com",
    messagingSenderId: "1068334513223",
    appId: "1:1068334513223:web:607ca83ce65e44a01a4562"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const getMessagingInstance = (): firebase.messaging.Messaging => {
    if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging()
        messaging.usePublicVapidKey(
            'BJkzZDykOIIIq32R713L51flAlz8TVLXRb6_EM0ctVAzTRo7txb8xgRJqG9HLqdATFF0s28Vv_tGCxhJ8dGM3AA'
        )
        return messaging
    } else {
        throw new Error('Firebase Messaging is not supported by Browser')
    }
}

export default firebase
