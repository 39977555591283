import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SetErrorNotificationAction, setErrorNotificationAction } from '../../actions/ui'
import { addPushToken, addPushTokenVariables } from '../generated/addPushToken'
import {
    sendPushNotification,
    sendPushNotificationVariables,
} from '../generated/sendPushNotification'
import { ADD_TOKEN_MUTATION, SEND_PUSH_NOTIFICATION } from '../mutations'

export const useAddTokenMutation = () => {
    const [addPushTokenHook] = useMutation<addPushToken, addPushTokenVariables>(ADD_TOKEN_MUTATION)

    return addPushTokenHook
}

export const useSendPushNotification = () => {
    const dispatch = useDispatch()
    const [sendPushNotificationMutation] = useMutation<sendPushNotification, sendPushNotificationVariables>(
        SEND_PUSH_NOTIFICATION,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(err?.message || 'Keine Daten gefunden!')
                ),
        }
    )

    return sendPushNotificationMutation
}
