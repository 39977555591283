import React from 'react'
import { useSelector } from 'react-redux'
import { getProbandsWithFoods_getProbands } from '../../../graphql/generated/getProbandsWithFoods'
import { useAddProbandToStudy } from '../../../graphql/hooks/study'
import { useGetProbandsWithFood } from '../../../graphql/hooks/user'
import { User } from '../../../models/User'
import { AppState } from '../../../state/app-state'
import { createImageDownloadLink, FoodTypeConverter, isAdmin } from '../../../utils/common'
import { export2csv, replaceSemicolonWithComma } from '../../../utils/csv'
import { millisStringToDate } from '../../../utils/date'
import { AttendingProbands } from './AttendingProbands'

interface Props {
    studyTitle?: string
}

export const AttendingProbandsContainer: React.FC<Props> = ({ studyTitle }) => {
    const { data, loading } = useGetProbandsWithFood()

    const addProbandToStudy = useAddProbandToStudy()
    const user = useSelector<AppState, User | null>((store) => store.data.user)

    const isAdminUser = isAdmin(user)

    const exportCsv = (selectedProbandIds: string[]) => {
        const dataForExport =
            data?.getFoods
                ?.filter((foodEntry) => selectedProbandIds.includes(foodEntry.userId))
                .map((food) => ({
                    id: food.id,
                    username: getUserNameFromUserId(food.userId, data.getProbands || []),
                    description: replaceSemicolonWithComma(food.description),
                    foodBrands:
                        food.foodBrands !== null
                            ? replaceSemicolonWithComma(food.foodBrands)
                            : null,
                    time: millisStringToDate(food.time),
                    createdAt: millisStringToDate(food.createdDate),
                    imageDownloadUrl:
                        food.picturePath !== null
                            ? createImageDownloadLink(food.picturePath)
                            : null,
                    imageId: food.picturePath,
                    type: FoodTypeConverter(food.type),
                })) || []
        export2csv(
            `Einträge der Studie ${studyTitle}`,
            dataForExport,
            `${studyTitle}_${new Date().toISOString()}`
        )
    }

    const handleAddProbandToStudy = (probandId: string, studyId: string) => {
        addProbandToStudy({
            variables: {
                probandId,
                studyId,
            },
        })
    }

    return (
        <AttendingProbands
            addProbandToStudy={handleAddProbandToStudy}
            exportCsv={exportCsv}
            foods={data?.getFoods || []}
            isAdminUser={isAdminUser}
            loading={loading}
            probands={data?.getProbands || []}
            studyTitle={studyTitle}
        />
    )
}

const getUserNameFromUserId = (userId: string, data: getProbandsWithFoods_getProbands[]) =>
    data.find((proband) => proband.id === userId)?.username
