import React from 'react'
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    Box,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import { BackButton } from '../../components/BackButton/BackButton'

export const Title = styled(Typography)`
    margin-bottom: 32px;
    margin-top: 24px;
`

export const HelpLoginScreen: React.FC = () => {
    return (
        <Box maxWidth={500} padding={1} margin="0 auto" marginTop={2}>
            <BackButton />
            <Title variant="h4">Wie können wir dir weiterhelfen?</Title>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>
                        Ich habe noch keine Probandennummer oder habe diese verloren.
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography align="justify">
                        {'Du kannst dich nur mit einer gültigen Probandennummer einloggen, die Du im' +
                            ' Rahmen einer Studie am Institut für Humanernährung der CAU Kiel erhältst.' +
                            ' Solltest Du deine Probandennummer nicht mehr wissen oder verloren haben,' +
                            ' bzw. dich für die Teilnahme an einer Studie interessieren, dann melde dich' +
                            ' bitte beim Studienteam unter '}
                        <a href="mailto:studie@nutrition.uni-kiel.de">
                            studie@nutrition.uni-kiel.de
                        </a>
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>Ich habe mein Passwort vergessen.</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        {'Wenn Du dein Passwort vergessen hast, melde dich bitte beim Studienteam des' +
                            'Instituts für Humanernährung der CAU Kiel unter '}
                        <a href="mailto:studie@nutrition.uni-kiel.de">
                            studie@nutrition.uni-kiel.de
                        </a>
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>
                        Ich möchte auch an einer Studie teilnehmen. Wie geht das?
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        {
                            'Wenn Du dich für die Teilnahme an einer Studie am Institut für Humanernährung der CAU Kiel interessierst, dann schick einfach eine Mail an '
                        }
                        <a href="mailto:studie@nutrition.uni-kiel.de">
                            studie@nutrition.uni-kiel.de
                        </a>
                        {
                            '. Das Studienteam wird dir dann Auskunft über die aktuell laufenden und kommenden Studien geben.'
                        }
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </Box>
    )
}
