import { Checkbox, MenuItem, Select, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Block from '@material-ui/icons/Block'
import Delete from '@material-ui/icons/DeleteForever'
import Export from '@material-ui/icons/SaveAlt'
import Reset from '@material-ui/icons/SettingsBackupRestore'
import MaterialTable, { Column } from 'material-table'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CreateUserDialog } from '../../../components/Dialog/CreateUserDialog'
import { SimpleDialog } from '../../../components/Dialog/SimpleDialog'
import { UserRole } from '../../../graphql/generated/globalTypes'
import { Study } from '../../../state/ui-state'
import { spacingLarge } from '../../../styles/spacing'
import { materialTableLocalization } from '../../../utils/de'
import icons from '../../../utils/table'

const TableWrapper = styled.div`
    margin-top: ${spacingLarge};
`

export type Proband = {
    id: string
    username: string
    numberOfEntries: number
    hasRegisteredWithOwnPassword: boolean
    userId: string
    hasActiveStudy: boolean
    study: Study | null
}

interface Props {
    probands: Proband[]
    isLoading: boolean
    isAdmin: boolean
    createProband: (username: string, studyId: string | null) => void
    deleteProband: (id: string) => void
    updateProbandStudy: (probandId: string, newStudyId: string) => void
    removeProbandFromStudy: (id: string) => void
    resetProbandPassword: (id: string) => void
    exportCsv: (probands: Proband[]) => void
    studiesOfAdmin: Study[]
}

export const ProbandsTable: React.FC<Props> = ({
    probands,
    studiesOfAdmin,
    isLoading,
    isAdmin,
    createProband,
    deleteProband,
    removeProbandFromStudy,
    resetProbandPassword,
    updateProbandStudy,
    exportCsv,
}) => {
    const [showUserDeleteDialog, setshowUserDeleteDialog] = useState(false)
    const [showUserRemoveFromStudyDialog, setShowRemoveFromStudyDialog] = useState(false)
    const [showUserResetPasswordDialog, setShowUserResetPasswordDialog] = useState(false)
    const [showCreateProbandDialog, setShowCreateProbandDialog] = useState(false)

    const [rowUserId, setrowUserId] = useState<null | string>(null)
    const [rowUsername, setRowUsername] = useState<null | string>(null)

    const getColumns = (): Column<Proband>[] => {
        const studyColumn = {
            title: 'In Studie eingetragen',
            field: 'hasActiveStudy',
            render: (data: Proband) => (
                <Checkbox
                    color="primary"
                    style={{ cursor: 'default' }}
                    checked={data.hasActiveStudy}
                />
            ),
        }
        const assignedStudyColumn = {
            title: 'Zugewiesene Studie',
            field: 'studyTitle',
            render: (proband: Proband) => (
                <Select
                    style={{ width: '100%' }}
                    onChange={(evt) => {
                        console.log('onChange')
                        if (evt.target.value) {
                            updateProbandStudy(proband.id, evt.target.value as string)
                        }
                    }}
                    value={proband.study?.id}
                >
                    {studiesOfAdmin.map((study) => (
                        <MenuItem value={study.id}>{study.title}</MenuItem>
                    ))}
                </Select>
            ),
        }
        return [
            { title: 'Probandennummer', field: 'username' },
            { title: 'Einträge', field: 'numberOfEntries' },
            {
                title: 'Registriert',
                field: 'hasRegisteredWithOwnPassword',
                render: (data) => (
                    <Checkbox
                        color="primary"
                        style={{ cursor: 'default' }}
                        checked={data.hasRegisteredWithOwnPassword}
                    />
                ),
            },
            ...(!isAdmin ? [studyColumn] : [assignedStudyColumn]),
            { title: 'UserId', field: 'userId', hidden: true },
        ]
    }

    const isAdminWithNoStudies = isAdmin && studiesOfAdmin.length === 0

    return (
        <div>
            {isAdmin && (
                <Typography>
                    Verwalte und Erstelle die Probanden für dir zugewiesene Studien. Die erfassten
                    Daten bleiben beim Verschieben eines Probanden in eine andere Studie erhalten.
                </Typography>
            )}
            <TableWrapper>
                <MaterialTable
                    isLoading={isLoading}
                    data={probands}
                    localization={materialTableLocalization}
                    title=""
                    options={{
                        actionsColumnIndex: -1,
                    }}
                    icons={icons as any}
                    actions={[
                        {
                            icon: Add,
                            iconProps: { color: 'primary' },
                            tooltip: isAdminWithNoStudies
                                ? 'Keine Studie zugewiesen'
                                : 'Probanden erstellen',
                            disabled: isAdminWithNoStudies,
                            isFreeAction: true,
                            onClick: () => setShowCreateProbandDialog(true),
                        },
                        (rowData: Proband) => ({
                            icon: Block,
                            tooltip: 'Von Studie entfernen',
                            isFreeAction: false,
                            hidden: isAdmin,
                            disabled: rowData.hasActiveStudy === false,
                            onClick: (event, data: any) => {
                                setrowUserId(data.userId)
                                setShowRemoveFromStudyDialog(true)
                            },
                        }),
                        (rowData: Proband) => ({
                            icon: Reset,
                            tooltip: 'Passwort zurücksetzen',
                            isFreeAction: false,
                            hidden: isAdmin,
                            disabled: !rowData.hasRegisteredWithOwnPassword,
                            onClick: (event, data: any) => {
                                setrowUserId(data.userId)
                                setShowUserResetPasswordDialog(true)
                            },
                        }),
                        {
                            icon: Delete,
                            tooltip: 'Probanden löschen',
                            isFreeAction: false,
                            onClick: (event, data: any) => {
                                setrowUserId(data.userId)
                                setRowUsername(data.username)
                                setshowUserDeleteDialog(true)
                            },
                        },
                        {
                            icon: Export,
                            tooltip: 'Als CSV exportieren',
                            isFreeAction: true,
                            onClick: () => exportCsv(probands),
                        },
                    ]}
                    columns={getColumns()}
                />
            </TableWrapper>
            <SimpleDialog
                title={`Proband ${rowUsername} löschen?`}
                onConfirm={() => {
                    if (rowUserId) {
                        deleteProband(rowUserId)
                    }
                    setshowUserDeleteDialog(false)
                }}
                confirmText="Löschen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setshowUserDeleteDialog(false)}
                open={showUserDeleteDialog}
                text={`Sind Sie sicher, dass der Proband ${rowUsername} gelöscht werden soll? Dieser Vorgang kann nicht rückgängig gemacht werden`}
            />
            <SimpleDialog
                title="Von Studie entfernen"
                onConfirm={() => {
                    if (rowUserId) {
                        removeProbandFromStudy(rowUserId)
                    }
                    setShowRemoveFromStudyDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowRemoveFromStudyDialog(false)}
                open={showUserRemoveFromStudyDialog}
                text="Soll dieser Proband von der aktuellen Studie entfernt werden?"
            />
            <SimpleDialog
                title="Passwort zurücksetzen"
                onConfirm={() => {
                    if (rowUserId) {
                        resetProbandPassword(rowUserId)
                    }
                    setShowUserResetPasswordDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowUserResetPasswordDialog(false)}
                open={showUserResetPasswordDialog}
                text="Soll für diesen Probanden das Passwort zurückgesetzt werden?"
            />
            <CreateUserDialog
                open={showCreateProbandDialog}
                role={UserRole.student}
                assignableStudies={isAdmin ? studiesOfAdmin : undefined}
                onDismiss={() => setShowCreateProbandDialog(false)}
                onConfirm={(username, studyId) => {
                    createProband(username, studyId)
                    setShowCreateProbandDialog(false)
                }}
            />
        </div>
    )
}
