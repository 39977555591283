import * as React from 'react'
import { List, Paper, Box } from '@material-ui/core'
import { getAllMyFood_getAllMyFood } from '../../graphql/generated/getAllMyFood'
import { EmptyFoodList } from './EmptyFood'
import { FoodListItem } from './FoodListItem'

export type Props = Readonly<{
    food: getAllMyFood_getAllMyFood[]
    handleClick: (id: string) => void
}>

export const DailyFoodList: React.FC<Props> = ({ food, handleClick }) => {
    return (
        <Box marginTop={2}>
            {food.length === 0 && <EmptyFoodList />}
            <Paper>
                <List style={{ paddingBottom: '0px', paddingTop: '0px' }} component="nav">
                    {food &&
                        food.map((item) => (
                            <FoodListItem onClick={handleClick} key={item.id} item={item} />
                        ))}
                </List>
            </Paper>
        </Box>
    )
}
