import { Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Background = styled(Paper)`
    margin: 1px;
    margin-bottom: 16px;
`

export const Header = styled(Typography)`
    font-weight: bold;
    padding: 8px;
    padding-left: 16px;
`
