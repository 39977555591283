import * as React from 'react'
import {
    makeStyles,
    Paper,
    Typography,
    useTheme,
    Button,
    Box,
    IconButton,
    MenuItem,
    Menu,
} from '@material-ui/core'
import { useState, useRef } from 'react'
import { ImageWrapper, UploadingProgess, PreviewImage } from './AddPhoto.styles'
import { PhotoStream, rtcConstraints } from '../../PhotoStream/PhotoStream'
import ExpandMoreIcon from '@material-ui/icons/MoreVert'
import { isSafariBrowser } from '../../../utils/common'
import { useSelector } from 'react-redux'
import { AppState } from '../../../state/app-state'

export type Props = Readonly<{
    uploadPhoto: (file: File) => Promise<string | void>
    progress: number
    showRtcNotification: () => void
    deletePhoto: (filename: string, deleteOnlyPhoto: boolean) => Promise<void>
    fileName: string | null
    initialPhotoSrc: string | null
    disabled?: boolean
    setImageIsUploading: (isUploading: boolean) => void
    setRtcPossible: (isUploading: boolean) => void
    isNewEntry: boolean
}>

// TODO refactor too large

export const AddPhoto: React.FC<Props> = ({
    uploadPhoto,
    progress,
    showRtcNotification,
    deletePhoto,
    fileName,
    initialPhotoSrc,
    disabled,
    setImageIsUploading,
    setRtcPossible,
    isNewEntry,
}) => {
    const theme = useTheme()

    const inputRef = useRef<HTMLInputElement>(null)

    const isRtcPossible = useSelector<AppState, boolean>((store) => store.ui.isRtcPossible)

    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | ArrayBuffer | null>(
        initialPhotoSrc
    )
    const [showStream, setshowStream] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null)

    const uploading = useSelector<AppState, boolean>((store) => store.ui.isImageUploading)

    const open = Boolean(anchorEl)

    const styles = makeStyles({
        typ: {
            padding: 2 * theme.spacing(),
            paddingTop: 1 * theme.spacing(),
            marginTop: 2 * theme.spacing(),
        },
        heading: {
            marginBottom: theme.spacing(),
        },
    })

    const handleUpload = (fileToUpload: File) => {
        setImageIsUploading(true)
        uploadPhoto(fileToUpload)
            .then((res) => setImageIsUploading(false))
            .catch((err) => {
                setImageIsUploading(false)
                setImagePreviewUrl(null)
            })
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handlePhotoDelete = () => {
        setAnchorEl(null)
        if (fileName) {
            deletePhoto(fileName, isNewEntry).then(() => {
                setImagePreviewUrl(null)
                setshowStream(false)
            })
        }
    }

    const handlePhotoTakenWithRtc = (blob: Blob, url: string) => {
        setshowStream(false)
        setImagePreviewUrl(url)
        const blobToFile = new File([blob], '.jpeg')
        handleUpload(blobToFile)
    }

    const checkIfRtcIsPossible = () => {
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            return navigator.mediaDevices
                .getUserMedia(rtcConstraints)
                .then((stream) => {
                    return true
                })
                .catch((err) => {
                    console.log('RTC not possible: ', err)
                    return false
                })
        }
    }

    const handleRtcClick = async () => {
        if (await checkIfRtcIsPossible()) {
            setshowStream(true)
            setRtcPossible(true)
        } else {
            setshowStream(false)
            setRtcPossible(false)
            showRtcNotification()
        }
    }

    const classes = styles()

    if (imagePreviewUrl === null && disabled) return <div />

    return (
        <Paper className={classes.typ}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography className={classes.heading} variant="h6">
                    Foto
                </Typography>
                {imagePreviewUrl && !disabled && (
                    <>
                        <IconButton onClick={handleClick}>
                            <ExpandMoreIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                            <MenuItem onClick={handlePhotoDelete}>Foto entfernen</MenuItem>
                        </Menu>
                    </>
                )}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center">
                <Box display="flex" width="100%" justifyContent="space-between">
                    {!imagePreviewUrl && (
                        <Button
                            style={{ margin: '0 auto' }}
                            onClick={(evt) => inputRef.current?.click()}
                            color="primary"
                            variant="contained"
                        >
                            Datei
                        </Button>
                    )}
                    {!imagePreviewUrl && !isSafariBrowser && isRtcPossible && (
                        <Button
                            style={{ margin: '0 auto' }}
                            color="primary"
                            onClick={handleRtcClick}
                            variant="contained"
                        >
                            Kamera
                        </Button>
                    )}
                </Box>
                <input
                    type="file"
                    ref={inputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={(evt) => {
                        const files = evt.currentTarget.files
                        if (files && files[0]) {
                            const reader = new FileReader()
                            reader.onloadend = () => {
                                setImagePreviewUrl(reader.result)
                                handleUpload(files[0])
                            }
                            reader.readAsDataURL(files[0])
                        }
                    }}
                />
                {imagePreviewUrl && (
                    <ImageWrapper
                        display="flex"
                        marginTop={1}
                        marginBottom={1}
                        justifyContent="center"
                    >
                        <PreviewImage
                            isLoading={uploading}
                            alt="preview"
                            src={imagePreviewUrl.toString()}
                        />
                        {uploading && (
                            <UploadingProgess
                                variant="determinate"
                                style={{ color: 'white' }}
                                value={progress}
                            />
                        )}
                    </ImageWrapper>
                )}

                {showStream && (
                    <PhotoStream
                        onStreamNotPossible={() => setshowStream(false)}
                        onStreamIsPossible={() => setshowStream(true)}
                        onImageCaptured={handlePhotoTakenWithRtc}
                    />
                )}
            </Box>
        </Paper>
    )
}
