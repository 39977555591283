import React from 'react'
import { ProbandDetailDialog } from './ProbandDetailDialog'
import { useGetFoodFromProband } from '../../../graphql/hooks/food'
import { millisStringToDate } from '../../../utils/date'
import { FoodTypeConverter } from '../../../utils/common'

interface Props {
    probandId: string
    onDismiss: () => void
    open: boolean
    username: string
}

export const ProbandDetailDialogContainer: React.FC<Props> = ({ username, probandId, ...rest }) => {
    const { data, loading } = useGetFoodFromProband(probandId)

    const foodEntriesWithDate =
        data?.getFoodFromProband?.map((food) => ({
            ...food,
            type: FoodTypeConverter(food.type),
            time: millisStringToDate(food.time),
            hasPicture: !!food.picturePath,
        })) || []

    return (
        <ProbandDetailDialog
            loading={loading}
            username={username}
            foodEntries={foodEntriesWithDate}
            {...rest}
        />
    )
}
