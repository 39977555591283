import React, { useState } from 'react'
import { getStudiesWithImageMetadata_getStudies as Study } from '../../graphql/generated/getStudiesWithImageMetadata'
import { useDeletePhotosFromDisk } from '../../graphql/hooks/images'
import { StudyStorageCard } from './StudyStorageCard'

interface Props {
    readonly study: Study
}

export const StudyStorageCardContainer: React.FC<Props> = ({ study }) => {
    const deletePhotos = useDeletePhotosFromDisk()
    const [showDeleteDialog, setshowDeleteDialog] = useState(false)

    const handleDelete = (fileNames: string[]) => {
        deletePhotos({ variables: { fileNames } }).then(() => setshowDeleteDialog(false))
    }

    return (
        <StudyStorageCard
            setShowDeleteDialog={setshowDeleteDialog}
            showDeleteDialog={showDeleteDialog}
            deletePhotos={handleDelete}
            study={study}
        />
    )
}
