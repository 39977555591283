import React from 'react'
import { useSelector } from 'react-redux'
import { UserRole } from '../../graphql/generated/globalTypes'
import { useCreateStudy, useDeleteStudy, useGetStudies } from '../../graphql/hooks/study'
import { useGetProbands } from '../../graphql/hooks/user'
import { AppState } from '../../state/app-state'
import { StudyScreen } from './StudyScreen'

export interface StudyData {
    id: string
    title: string
    numberOfProbands: number
}

export const StudyScreenContainer: React.FC = () => {
    const userRole = useSelector<AppState, UserRole | undefined>((store) => store.data.user?.role)
    const {data: studiesData, loading} = useGetStudies()

    const createStudyHook = useCreateStudy()
    const { data: probandsData } = useGetProbands()
    const deleteStudy = useDeleteStudy()

    const handleCreateStudy = (title: string) => {
        createStudyHook({
            variables: {
                title,
            },
        })
    }

    const handleDeleteStudy = (id: string) => {
        deleteStudy({
            variables: {
                id,
            },
        })
    }

    const studyData: StudyData[] =
        studiesData?.getStudies
            ?.map((study) => ({
                id: study.id,
                title: study.title,
                numberOfProbands:
                    probandsData?.getProbands?.filter((proband) => proband.study?.id === study.id)
                        .length || [].length,
            }))
            .sort(function (a, b) {
                if (a.title > b.title) {
                    return 1
                }
                if (b.title > a.title) {
                    return -1
                }
                return 0
            }) || []

    return (
        <StudyScreen
            isLoading={loading}
            userRole={userRole}
            deleteStudy={handleDeleteStudy}
            createStudy={handleCreateStudy}
            studies={studyData}
        />
    )
}
