import { DatePicker } from '@material-ui/pickers'
import styled from 'styled-components'

export const StyledPicker = styled(DatePicker)`
    .MuiInputBase-root {
        text-align: center !important;
        font-weight: bold;
        color: #029688;
        font-size: 1.1rem;
        input {
            text-align: center !important;
        }
        ::before {
            border: none;
            display: none;
        }
    }
`
