import React, { useState } from 'react'
import { AddPhoto } from './AddPhoto'
import { useDispatch, useSelector } from 'react-redux'
import {
    SetErrorNotificationAction,
    setErrorNotificationAction,
    SetNotificationAction,
    setNotificationAction,
    SetImageIsUploadingAction,
    setImageIsUploadingAction,
    SetRtcPossibeAction,
    setRtcPossibleAction,
} from '../../../actions/ui'
import axios, { AxiosResponse } from 'axios'
import { AppState } from '../../../state/app-state'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_PHOTO_MUTATION } from '../../../graphql/mutations'
import { deletePhoto, deletePhotoVariables } from '../../../graphql/generated/deletePhoto'
interface Props {
    onImageChange: (file: string | null) => void
    initialImageSource: string | null
    disabled?: boolean
    filename: string | null
    isNewEntry: boolean
}

interface ServerData {
    fileName: string
    filePath: string
}

export const AddPhotoContainer: React.FC<Props> = ({
    onImageChange,
    initialImageSource,
    disabled = false,
    filename,
    isNewEntry,
}) => {
    const dispatch = useDispatch()

    const [progress, setProgress] = useState(0)
    const [imageFileName, setfilename] = useState<string | null>(filename)

    const username = useSelector<AppState, string>((store) => store.data.user?.username || '')

    const [deletePhotoMutation] = useMutation<deletePhoto, deletePhotoVariables>(
        DELETE_PHOTO_MUTATION
    )

    const uploadPhotoRest = async (file: File) => {
        const formData = new FormData()
        formData.append('file', file as Blob)
        return new Promise<string | void>(async (resolve, reject) => {
            try {
                const res: AxiosResponse<ServerData> = await axios.post(
                    `${process.env.REACT_APP_FILEUPLOAD_URL}/${username}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                        onUploadProgress: (progressEvent: ProgressEvent) => {
                            setProgress(
                                Math.round((progressEvent.loaded * 100) / progressEvent.total)
                            )
                        },
                    }
                )
                const { fileName } = res.data
                onImageChange(fileName)
                setfilename(fileName)
                resolve()
            } catch (err: any) {
                reject(err)
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(err.response.data.msg)
                )
            }
        })
    }

    const handleDeletePhoto = async (fileName: string, deleteOnlyPhoto: boolean) => {
        try {
            await deletePhotoMutation({
                variables: {
                    fileName,
                    onlyPhoto: deleteOnlyPhoto,
                },
            })

            onImageChange(null)
        } catch (error: any) {
            dispatch<SetErrorNotificationAction>(setErrorNotificationAction(error.message))
        }
    }

    const showRtcNotification = () => {
        dispatch<SetNotificationAction>(
            setNotificationAction({
                severity: 'info',
                text: 'Ihr Gerät unterstützt keine direkte Verbindung zur Kamera über WebRTC. Bitte Datei auswählen, um ein Foto hinzuzufügen',
            })
        )
    }

    const setRtcPossible = (isPossible: boolean) =>
        dispatch<SetRtcPossibeAction>(setRtcPossibleAction(isPossible))

    const handleIsImageUploading = (isUploading: boolean) =>
        dispatch<SetImageIsUploadingAction>(setImageIsUploadingAction(isUploading))

    return (
        <AddPhoto
            setImageIsUploading={handleIsImageUploading}
            showRtcNotification={showRtcNotification}
            deletePhoto={handleDeletePhoto}
            fileName={imageFileName}
            progress={progress}
            uploadPhoto={uploadPhotoRest}
            initialPhotoSrc={initialImageSource}
            disabled={disabled}
            setRtcPossible={setRtcPossible}
            isNewEntry={isNewEntry}
        />
    )
}
