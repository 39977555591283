import * as React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from '@material-ui/core'

export type Props = Readonly<{
    text: string
    confirmText: string
    dismissText: string
    open: boolean
    onDismiss: () => void
    onConfirm: () => void
    title: string
    confirmColor?: 'primary' | 'inherit' | 'secondary' | 'default'
}>

export const SimpleDialog: React.FC<Props> = ({
    text,
    confirmText,
    confirmColor = 'primary',
    dismissText,
    open,
    onDismiss,
    onConfirm,
    title,
}) => {
    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss} color="primary">
                    {dismissText}
                </Button>
                <Button onClick={onConfirm} color={confirmColor} autoFocus>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
