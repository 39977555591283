import { ApolloProvider } from '@apollo/react-hooks'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { ApolloLink } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import 'firebase/firebase-messaging'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { AppContainer } from './App.container'
import './index.css'
import { dataReducer } from './reducer/data-reducer'
import { uiReducer } from './reducer/ui-reducer'
import * as serviceWorker from './serviceWorker'
import { initialAppState } from './state/app-state'

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'include',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        )

    if (networkError) console.log(`[Network error]: ${networkError}`)
})

export const client = new ApolloClient({
    link: ApolloLink.from([errorLink, uploadLink]),
    cache: new InMemoryCache(),
})

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#029688',
            dark: '#00675B',
        },
    },
})

const createReduxStore = () => {
    const appReducer = combineReducers({
        ui: uiReducer,
        data: dataReducer,
    })
    const enhancers = composeWithDevTools()

    const store = createStore(appReducer, initialAppState, enhancers)

    return store
}

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Provider store={createReduxStore()}>
                <ThemeProvider theme={theme}>
                    <AppContainer />
                </ThemeProvider>
            </Provider>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
