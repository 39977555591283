import { AppAction } from '../actions/actions'
import { ActionType } from '../actions/ui'
import { UiState, initialUiState } from '../state/ui-state'

export const uiReducer = (state: UiState = initialUiState, action: AppAction): UiState => {
    switch (action.type) {
        case ActionType.SetNotification:
            return {
                ...state,
                notification: {
                    message: action.notification.text,
                    severity: action.notification.severity,
                    showNotification: true,
                },
            }
        case ActionType.DismissNotification: {
            return {
                ...state,
                notification: {
                    ...state.notification,
                    message: '',
                    showNotification: false,
                },
            }
        }

        case ActionType.SetErrorNotification: {
            return {
                ...state,
                notification: {
                    message: action.message,
                    showNotification: true,
                    severity: 'error',
                },
            }
        }

        case ActionType.SetDrawerTitle: {
            return {
                ...state,
                drawerTitle: action.title,
            }
        }

        case ActionType.SetAppBarItemClicked: {
            return {
                ...state,
                appBarItemClicked: action.item,
            }
        }

        case ActionType.DismissAppBarClicks: {
            return {
                ...state,
                appBarItemClicked: null,
            }
        }

        case ActionType.SetPage: {
            return {
                ...state,
                location: {
                    page: action.page,
                },
            }
        }

        case ActionType.SetIsRtcPossible: {
            return {
                ...state,
                isRtcPossible: action.isPossible,
            }
        }

        case ActionType.SetImageIsUploading: {
            return {
                ...state,
                isImageUploading: action.isUploading,
            }
        }
        default:
            return state
    }
}
