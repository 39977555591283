import * as React from 'react'
import { getAllMyFood_getAllMyFood } from '../../graphql/generated/getAllMyFood'
import { FoodType } from '../../graphql/generated/globalTypes'
import { List } from '@material-ui/core'
import { FoodListItem } from '../DailyFoodList/FoodListItem'
import { Background, Header } from './HistoryBox.styles'
import { useHistory } from 'react-router-dom'

export type Props = Readonly<{
    food: getAllMyFood_getAllMyFood[]
    title: string
    type: FoodType
}>

export const HistoryBox: React.FC<Props> = ({ title, food, type }) => {
    const history = useHistory()
    if (food.length === 0) return <div />

    return (
        <Background>
            <Header variant="h6">{title}</Header>
            <List>
                {food.map((item, index) => (
                    <FoodListItem
                        showDivider={index !== food.length - 1}
                        key={item.id}
                        item={item}
                        onClick={() => history.push(`/edit/${item.id}`)}
                    />
                ))}
            </List>
        </Background>
    )
}
