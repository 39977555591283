import * as React from 'react'
import { useTheme, makeStyles, Paper, Typography, ButtonGroup, Button } from '@material-ui/core'
import { FoodType } from '../../graphql/generated/globalTypes'
import { useState } from 'react'

export type Props = Readonly<{
    value: FoodType
    onTypeChange: (type: FoodType) => void
    disabled?: boolean
}>

export const TypeChoice: React.FC<Props> = ({ value, onTypeChange, disabled = false }) => {
    const theme = useTheme()

    const [selectedButton, setSelectedButton] = useState<FoodType>(value)

    const styles = makeStyles({
        typ: {
            padding: 2 * theme.spacing(),
            paddingTop: 1 * theme.spacing(),
        },
        heading: {
            marginBottom: theme.spacing(),
        },
        buttonGroup: {
            display: 'flex',
            justifyContent: 'center',
        },
    })

    const classes = styles()

    const getButtonVariant = (type: FoodType) => {
        if (type === selectedButton) return 'contained'
        return 'outlined'
    }

    const handleButtonClick = (type: FoodType) => {
        setSelectedButton(type)
        onTypeChange(type)
    }
    return (
        <Paper className={classes.typ}>
            <Typography className={classes.heading} variant="h6">
                Typ
            </Typography>
            <ButtonGroup
                color="primary"
                variant="outlined"
                aria-label="outlined primary button group"
                className={classes.buttonGroup}
                disabled={disabled}
            >
                <Button
                    onClick={() => handleButtonClick(FoodType.snack)}
                    variant={getButtonVariant(FoodType.snack)}
                >
                    Snack
                </Button>
                <Button
                    onClick={() => handleButtonClick(FoodType.meal)}
                    variant={getButtonVariant(FoodType.meal)}
                >
                    Mahlzeit
                </Button>
                <Button
                    onClick={() => handleButtonClick(FoodType.drink)}
                    variant={getButtonVariant(FoodType.drink)}
                >
                    Getränk
                </Button>
            </ButtonGroup>
        </Paper>
    )
}
