import { AppAction } from '../actions/actions'
import { ActionType } from '../actions/data'
import { DataState, initialDataState } from '../state/data-state'

export const dataReducer = (state: DataState = initialDataState, action: AppAction): DataState => {
    switch (action.type) {
        case ActionType.RECEIVE_CURRENT_USER:
            return {
                ...state,
                user: action.user,
            }
        case ActionType.LOGOUT_CURRENT_USER: {
            return {
                ...state,
                user: null,
            }
        }

        default:
            return state
    }
}
