import DateFnsUtils from '@date-io/date-fns'
import { Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import deLocale from 'date-fns/locale/de'
import * as React from 'react'
import { useState } from 'react'
import { Prompt } from 'react-router-dom'
import { AddPhotoContainer } from '../../components/CreateFood/AddPhoto/AddPhoto.container'
import { DateChoice } from '../../components/CreateFood/DateChoice'
import { Description } from '../../components/CreateFood/Description'
import { FoodBrands } from '../../components/CreateFood/FoodBrands'
import { TypeChoice } from '../../components/CreateFood/TypChoice'
import { SimpleDialog } from '../../components/Dialog/SimpleDialog'
import { FoodType } from '../../graphql/generated/globalTypes'
import { updateFoodVariables } from '../../graphql/generated/updateFood'
import { Food } from '../../models'
import { AppBarItem } from '../../state/ui-state'
import { spacingMedium } from '../../styles/spacing'
import {
    filterValuesForAutocompleteDescription,
    filterValuesForAutocompleteFoodBrands,
} from '../CreateFood/utils/autocomplete'
import { DeleteButton, ReadOnlyBanner, WarningIcon } from './EditFoodScreen.styles'

interface Props {
    clickedMenuItems: AppBarItem | null
    resetSaveButton: () => void
    updateFood: (update: updateFoodVariables) => Promise<void>
    food: Food
    isEditable: boolean
    deleteFood: (foodId: string) => Promise<void>
    historicFood: Food[]
}

interface InputError {
    description: string | null
    date: string | null
}

export const EditFoodScreen: React.FC<Props> = ({
    clickedMenuItems,
    resetSaveButton,
    updateFood,
    food,
    isEditable,
    deleteFood,
    historicFood,
}) => {
    React.useEffect(() => {
        if (clickedMenuItems === 'editFoodEntry') {
            setShowPrompt(false)
            if (description.length === 0) {
                setInputError({ ...inputError, description: 'Keine Beschreibung angegeben!' })
            } else {
                updateFood({
                    id: food.id,
                    update: {
                        description,
                        time: date.toISOString(),
                        type,
                        picturePath: imageFilename,
                        foodBrands: foodBrands !== '' ? foodBrands : null,
                    },
                })
            }
            resetSaveButton()
        }
    }, [clickedMenuItems, resetSaveButton])

    const [type, setType] = useState<FoodType>(food.type)
    const [date, setDate] = useState<Date>(new Date(parseInt(food.time, 10)))
    const [description, setDescription] = useState<string>(food.description)
    const [foodBrands, setFoodBrands] = useState<string>(food.foodBrands ?? '')
    const [imageFilename, setImageFile] = useState<string | null>(food.picturePath)
    const [inputError, setInputError] = useState<InputError>({ date: null, description: null })
    const [showPrompt, setShowPrompt] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
            <>
                {!isEditable && (
                    <ReadOnlyBanner>
                        <WarningIcon />
                        <Typography variant="body2">
                            Dieser Eintrag ist älter als 48h und kann nicht mehr bearbeitet werden.
                        </Typography>
                    </ReadOnlyBanner>
                )}
                <Prompt when={showPrompt} message="Ungespeicherte Änderungen verwerfen?" />
                <TypeChoice
                    disabled={!isEditable}
                    value={type}
                    onTypeChange={(type) => {
                        setShowPrompt(true)
                        setType(type)
                    }}
                />
                <DateChoice
                    disabled={!isEditable}
                    withChips={false}
                    value={date}
                    onDateChange={(date) => {
                        setShowPrompt(true)
                        setDate(date)
                    }}
                />
                <Description
                    valuesForAutocomplete={filterValuesForAutocompleteDescription(
                        historicFood,
                        type
                    )}
                    disabled={!isEditable}
                    errorMessage={inputError.description}
                    value={description}
                    onDescriptionChange={(description) => {
                        setDescription(description)
                        setShowPrompt(true)
                        setInputError({ ...inputError, description: null })
                    }}
                />
                <FoodBrands
                    disabled={!isEditable}
                    value={foodBrands}
                    valuesForAutocomplete={filterValuesForAutocompleteFoodBrands(
                        historicFood,
                        type
                    )}
                    onFoodBrandsChange={(value) => {
                        setShowPrompt(true)
                        setFoodBrands(value)
                    }}
                />
                <AddPhotoContainer
                    isNewEntry={false}
                    filename={imageFilename}
                    initialImageSource={
                        food.picturePath
                            ? `${process.env.REACT_APP_THUMBNAIL_URL}/?src=/${food.picturePath}&noresize=1`
                            : null
                    }
                    disabled={!isEditable}
                    onImageChange={(filename) => {
                        setShowPrompt(true)
                        setImageFile(filename)
                    }}
                />
                {isEditable && (
                    <DeleteButton
                        variant="contained"
                        style={{ marginTop: spacingMedium }}
                        fullWidth
                        color="secondary"
                        startIcon={<DeleteIcon />}
                        onClick={() => setShowDeleteDialog(true)}
                    >
                        Eintrag löschen
                    </DeleteButton>
                )}
                <SimpleDialog
                    confirmText="Löschen"
                    dismissText="Abbrechen"
                    onDismiss={() => setShowDeleteDialog(false)}
                    onConfirm={() => deleteFood(food.id)}
                    open={showDeleteDialog}
                    title="Eintrag löschen"
                    text="Sind Sie sicher, dass dieser Eintrag gelöscht werden soll? Dieser Vorgang kann nicht rückgängig gemacht werden."
                />
            </>
        </MuiPickersUtilsProvider>
    )
}
