import { useMutation } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import { SetErrorNotificationAction, setErrorNotificationAction } from '../../actions/ui'
import {
    deletePhotosFromDisk,
    deletePhotosFromDiskVariables,
} from '../generated/deletePhotosFromDisk'
import { DELETE_PHOTOS_FROM_DISK_MUTATION } from '../mutations'
import { GET_STUDIES_WITH_IMAGE_METADATA } from '../queries'

export const useDeletePhotosFromDisk = () => {
    const dispatch = useDispatch()
    const [deletePhotosFromDiskMutation] = useMutation<
        deletePhotosFromDisk,
        deletePhotosFromDiskVariables
    >(DELETE_PHOTOS_FROM_DISK_MUTATION, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(err?.message || 'Fehler beim Löschen der Fotos')
            ),
        refetchQueries: [{ query: GET_STUDIES_WITH_IMAGE_METADATA }],
    })

    return deletePhotosFromDiskMutation
}
