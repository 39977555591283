import React from 'react'
import { AddProbandToStudyDialog } from './AddProbandToStudyDialog'
import { useGetProbands } from '../../../graphql/hooks/user'

interface Props {
    open: boolean
    onDismiss: () => void
    onConfirm: (id: string) => void
}

export const AddProbandToStudyDialogContainer: React.FC<Props> = ({ ...rest }) => {
    const { data } = useGetProbands()

    const probandsWithoutActiveStudy =
        data?.getProbands?.filter((proband) => proband.study === null) || []

    return <AddProbandToStudyDialog probands={probandsWithoutActiveStudy} {...rest} />
}
