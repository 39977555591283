import sortBy from 'lodash.sortby'
import React from 'react'
import { useSelector } from 'react-redux'
import { UserRole } from '../../../graphql/generated/globalTypes'
import { useAddProbandToStudy, useGetStudies } from '../../../graphql/hooks/study'
import {
    useCreateUser,
    useDeleteUser,
    useGetProbandsWithFood,
    useRemoveProbandFromStudy,
    useResetPassword,
    useUpdateProbandStudy,
} from '../../../graphql/hooks/user'
import { GET_PROBANDS_QUERY } from '../../../graphql/queries'
import { User } from '../../../models/User'
import { AppState } from '../../../state/app-state'
import { isAdmin } from '../../../utils/common'
import { export2csv } from '../../../utils/csv'
import { Proband, ProbandsTable } from './ProbandsTable'

export const ProbandsTableContainer: React.FC = () => {
    const user = useSelector<AppState, User | null>((store) => store.data.user)
    const isAdminUser = isAdmin(user)

    const { data, loading } = useGetProbandsWithFood()
    const { data: studiesData, loading: areStudiesLoading } = useGetStudies()
    const createUser = useCreateUser()
    const deleteProband = useDeleteUser(isAdminUser)
    const removeProbandFromStudy = useRemoveProbandFromStudy()
    const resetProbandPassword = useResetPassword()
    const addProbandToStudy = useAddProbandToStudy()
    const updateProbandStudy = useUpdateProbandStudy()

    const handleCreateProband = (username: string, studyId: string | null) => {
        createUser({
            variables: {
                username,
                role: UserRole.student,
            },
            refetchQueries: [{ query: GET_PROBANDS_QUERY }],
        })
            .then((user) => {
                if (isAdminUser && studyId !== null && user.data?.createUser.id) {
                    addProbandToStudy({
                        variables: { probandId: user.data?.createUser.id, studyId },
                    })
                }
            })
            .catch((err) => console.log(err))
    }

    const handleUpdateProbandStudy = (probandId: string, newStudyId: string) => {
        updateProbandStudy({
            variables: {
                probandId,
                newStudyId,
            },
        })
    }

    const exportCsv = (data: Proband[]) => {
        const dataForExport = data.map((proband) => ({
            id: proband.id,
            username: proband.username,
            numberOfEntries: proband.numberOfEntries,
            registered: proband.hasRegisteredWithOwnPassword,
            hasActiveStudy: proband.hasActiveStudy,
        }))
        export2csv('Probanden', dataForExport, `Probanden_${new Date().toISOString()}`)
    }

    const handleDeleteProband = (id: string) => {
        deleteProband({
            variables: {
                id,
            },
        })
    }

    const handleRemoveProbandFromStudy = (probandId: string) => {
        removeProbandFromStudy({
            variables: {
                probandId,
            },
        })
    }
    const handleResetProbandPassword = (probandId: string) => {
        resetProbandPassword({
            variables: {
                userId: probandId,
            },
        })
    }

    const studyIdsManagedByAdmin = studiesData?.getStudies?.map((study) => study.id)

    const probandsFiltered = isAdminUser
        ? data?.getProbands?.filter(
              (proband) => proband.study?.id && studyIdsManagedByAdmin?.includes(proband.study?.id)
          )
        : data?.getProbands

    const probands: Proband[] =
        probandsFiltered?.map((proband) => ({
            hasRegisteredWithOwnPassword: proband.hasRegisteredWithOwnPassword,
            id: proband.id,
            numberOfEntries:
                data?.getFoods
                    ?.filter((food) => food.userId !== null)
                    .filter((food) => food.userId === proband.id).length || 0,
            username: proband.username,
            userId: proband.id,
            hasActiveStudy: !!proband.study?.id,
            study: proband.study,
        })) || []

    return (
        <ProbandsTable
            createProband={handleCreateProband}
            updateProbandStudy={handleUpdateProbandStudy}
            deleteProband={handleDeleteProband}
            removeProbandFromStudy={handleRemoveProbandFromStudy}
            resetProbandPassword={handleResetProbandPassword}
            isLoading={loading || areStudiesLoading}
            isAdmin={isAdminUser}
            exportCsv={exportCsv}
            probands={sortBy(probands, (proband) => proband.username)}
            studiesOfAdmin={studiesData?.getStudies ?? []}
        />
    )
}
