import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as React from 'react'
import { useState } from 'react'
import { getAdmins_getAdmins } from '../../../graphql/generated/getAdmins'
import { Selection } from '../../../models/Selection'

export type Props = Readonly<{
    open: boolean
    onDismiss: () => void
    onConfirm: (adminId: string, studyId: string) => void
    admins: getAdmins_getAdmins[]
    studyId: string
}>

export const AddAdminToStudyDialog: React.FC<Props> = ({
    open,
    onDismiss,
    onConfirm,
    admins,
    studyId,
}) => {
    const [adminId, setAdminId] = useState<string | null>(null)

    React.useEffect(() => {
        setAdminId(null)
    }, [open])

    const adminSelection: Selection[] = admins.map((admin) => ({
        label: admin.username,
        value: admin.id,
    }))

    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">AdministratorIn zur Studie hinzufügen</DialogTitle>
            <DialogContent>
                <Autocomplete
                    id="combo-box-demo"
                    options={adminSelection}
                    getOptionLabel={(option) => option.label}
                    noOptionsText="Keine Administratoren vorhanden"
                    onChange={(evt: any, selection: Selection | null) => {
                        if (selection) {
                            setAdminId(selection.value)
                        }
                    }}
                    placeholder="Proband"
                    getOptionSelected={(option, value) => value.value === option.value}
                    renderInput={(params) => (
                        <TextField {...params} label="AdministratorIn wählen" variant="outlined" />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss}>Abbrechen</Button>
                <Button
                    disabled={adminId === null}
                    onClick={() => (adminId ? onConfirm(adminId, studyId) : {})}
                    color="primary"
                    autoFocus
                >
                    hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
