import { Box, Button, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Skeleton } from '@material-ui/lab'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CreateStudyDialog } from '../../components/Dialog/CreateStudyDialog'
import { SimpleDialog } from '../../components/Dialog/SimpleDialog'
import { AddStudy } from '../../components/Study/AddStudy'
import { Study } from '../../components/Study/Study'
import { UserRole } from '../../graphql/generated/globalTypes'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import { spacingMedium } from '../../styles/spacing'
import { AttendingProbandsContainer } from './AttendingProbands/AttendingProbands.container'
import { ManagedByContainer } from './ManagedBy/ManagedBy.container'
import { StudyData } from './StudyScreen.container'

interface Props {
    studies: StudyData[]
    createStudy: (title: string) => void
    deleteStudy: (id: string) => void
    userRole: UserRole | undefined
    isLoading: boolean
}

const StudySceleton = styled(Skeleton)`
    border-radius: 4px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: ${spacingMedium};
`

export const StudyScreen: React.FC<Props> = ({
    studies,
    createStudy,
    deleteStudy,
    userRole,
    isLoading,
}) => {
    const [showCreateDialog, setshowCreateDialog] = useState(false)
    const [showDeleteStudyDialog, setshowDeleteStudyDialog] = useState(false)
    useDrawerTitle('Studien')

    const { id } = useParams<{ id: string | undefined }>()

    const isRoot = userRole === UserRole.root
    const isAdmin = userRole === UserRole.admin
    const noStudiesExist = studies.length === 0

    const history = useHistory()

    const selectedStudy: StudyData | undefined = studies.find((study) => study.id === id)

    return (
        <div>
            <Box
                style={{ marginBottom: spacingMedium }}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h4">
                    {isRoot ? 'Studien' : 'Dir zugewiesene Studien'}
                </Typography>
                {id && isRoot && (
                    <Button
                        onClick={() => setshowDeleteStudyDialog(true)}
                        variant="contained"
                        color="secondary"
                        startIcon={<DeleteIcon />}
                    >
                        Löschen
                    </Button>
                )}
            </Box>
            <Grid>
                {isLoading && (
                    <>
                        <StudySceleton variant="rect" width="100%" height={100} />
                        <StudySceleton variant="rect" width="100%" height={100} />
                    </>
                )}
                {studies !== null &&
                    studies!.map((study) => (
                        <Study
                            isSelected={id === study.id}
                            id={study.id}
                            key={study.id}
                            title={study.title}
                            numberOfProbands={study.numberOfProbands}
                        />
                    ))}
                {isRoot && <AddStudy onClick={() => setshowCreateDialog(true)} />}
            </Grid>

            {selectedStudy === undefined ? (
                <Typography style={{ marginTop: spacingMedium }}>
                    {noStudiesExist ? 'Keine Studie vorhanden' : 'Keine Studie ausgewählt'}
                </Typography>
            ) : (
                <>
                    {isAdmin && (
                        <AttendingProbandsContainer
                            studyTitle={selectedStudy ? selectedStudy.title : ''}
                        />
                    )}

                    {isRoot && (
                        <>
                            <AttendingProbandsContainer
                                studyTitle={selectedStudy ? selectedStudy.title : ''}
                            />
                            <ManagedByContainer
                                studyTitle={selectedStudy ? selectedStudy.title : ''}
                            />
                        </>
                    )}
                </>
            )}

            <CreateStudyDialog
                open={showCreateDialog}
                onDismiss={() => setshowCreateDialog(false)}
                onConfirm={(title) => {
                    createStudy(title)
                    setshowCreateDialog(false)
                }}
            />
            <SimpleDialog
                title="Studie löschen"
                onDismiss={() => setshowDeleteStudyDialog(false)}
                onConfirm={() => {
                    if (id) {
                        deleteStudy(id)
                        history.push('/backoffice/studies')
                    }
                }}
                open={showDeleteStudyDialog}
                dismissText="Abbrechen"
                confirmColor="secondary"
                confirmText="Löschen"
                text="Soll diese Studie wirklich endgültig entfernt werden? Dieser Vorgang kann nicht rückgängig gemacht werden."
            />
        </div>
    )
}
