import { Box, Card, Typography } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

interface Props {
    title: string
    id: string
    isSelected: boolean
    numberOfProbands: number
}

export const CustomCard = styled(Card)<{ selected?: boolean }>`
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 8px;
    cursor: pointer;
    background-color: ${(props) => (props.selected ? 'rgba(2,150,136,0.47)' : 'white')};
    transition: box-shadow 0.3s;
    user-select: none;
    &:hover {
        box-shadow: 0 0 7px rgba(33, 33, 33, 0.5);
    }
`

export const Study: React.FC<Props> = ({ title, numberOfProbands, id, isSelected }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/backoffice/studies/${id}`)
    }

    return (
        <CustomCard onClick={handleClick} selected={isSelected}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography
                    style={{
                        padding: '8px',
                        whiteSpace: 'nowrap',
                        fontSize: '1.15rem',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                    variant="h6"
                >
                    {title}
                </Typography>
            </Box>
            <Typography style={{ padding: '8px', marginBottom: '16px' }} variant="body1">
                {`${numberOfProbands} ${numberOfProbands === 1 ? 'Proband' : 'Probanden'}`}
            </Typography>
        </CustomCard>
    )
}
