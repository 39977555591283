import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core'
import * as React from 'react'

export type Props = Readonly<{
    open: boolean
    onDismiss: () => void
    onConfirm: () => void
    photos: string[]
}>

export const DeletePhotosDialog: React.FC<Props> = ({ open, onDismiss, onConfirm, photos }) => {
    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Bilder löschen</DialogTitle>
            <DialogContent>
                <Typography>
                    Sind Sie sicher, dass folgende Bilder endgültig gelöscht werden sollen:
                </Typography>
                <List dense>
                    {photos.map((photo) => (
                        <ListItem key={photo}>
                            <ListItemText primary={photo} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={onConfirm} color="secondary" autoFocus>
                    Bilder löschen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
