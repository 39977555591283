import { Typography } from '@material-ui/core'
import React from 'react'
import { useGetAvailableSpace } from '../../graphql/hooks/space'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import { SpaceScreen } from './SpaceScreen'

export const SpaceScreenContainer: React.FC = () => {
    useDrawerTitle("Speicher")
    const {data, loading} = useGetAvailableSpace()

    if(loading) return <Typography>Speicherplatz wird geladen..</Typography>

    if(data?.getAvailableSpaceInMegaByte === undefined && !loading) return <Typography>Speicherplatz konnte nicht geladen werden</Typography>

    return <SpaceScreen availableSpace={data?.getAvailableSpaceInMegaByte!} />
}
