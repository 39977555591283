import gql from 'graphql-tag'

//TODO Add Food Fragment
export const ME_QUERY = gql`
    query me {
        me {
            id
            username
            role
            study {
                title
            }
        }
    }
`

export const GET_ALL_MY_FOOD_QUERY = gql`
    query getAllMyFood {
        getAllMyFood {
            id
            type
            description
            time
            picturePath
            foodBrands
        }
    }
`
export const GET_FOOD_QUERY = gql`
    query getFood($id: String!) {
        getFood(id: $id) {
            id
            type
            description
            time
            picturePath
            foodBrands
        }
    }
`

export const GET_STUDIES_QUERY = gql`
    query getStudies {
        getStudies {
            id
            title
            managedBy {
                id
                username
            }
        }
    }
`
export const GET_STUDIES_WITH_IMAGE_METADATA = gql`
    query getStudiesWithImageMetadata {
        getStudies {
            id
            title
            imagesMetadata {
                countOfImages
                totalSizeInMB
                filenames
            }
        }
    }
`

export const GET_PROBANDS_QUERY = gql`
    query getProbands {
        getProbands {
            id
            username
            role
            hasRegisteredWithOwnPassword
            study {
                id
                title
            }
        }
    }
`

export const GET_ADMINS_QUERY = gql`
    query getAdmins {
        getAdmins {
            id
            username
            hasRegisteredWithOwnPassword
            managedStudies {
                id
                title
            }
        }
    }
`

export const GET_ROOTS_QUERY = gql`
    query getRoots {
        getRoots {
            id
            username
            hasRegisteredWithOwnPassword
        }
    }
`

export const GET_FOOD_FROM_PROBAND = gql`
    query getFoodFromProband($probandId: String!) {
        getFoodFromProband(probandId: $probandId) {
            id
            type
            description
            time
            picturePath
            foodBrands
        }
    }
`

export const GET_PROBANDS_WITH_FOOD_QUERY = gql`
    query getProbandsWithFoods {
        getProbands {
            id
            username
            hasRegisteredWithOwnPassword
            study {
                id
                title
            }
        }
        getFoods {
            id
            userId
            time
            type
            picturePath
            description
            createdDate
            foodBrands
        }
    }
`

export const GET_AVAILABLE_SPACE = gql`
    query getAvailableSpaceInMegaByte {
        getAvailableSpaceInMegaByte {
            totalInMB
            freeInMB
        }
    }
`
