import * as React from 'react'
import { Typography, Box } from '@material-ui/core'
import { MealCard } from '../../components/MealCard/MealCard'
import DailyFoodListContainer from '../../components/DailyFoodList/DailyFoodList.container'
import { useHistory } from 'react-router-dom'
import { FoodType } from '../../graphql/generated/globalTypes'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import { usePage } from '../../hooks/usePage'

export const Dashboard: React.FC = () => {
    const history = useHistory()
    useDrawerTitle('Übersicht')
    usePage('home')

    const handleNavigation = (type: FoodType) => {
        history.push('/new', type)
    }
    return (
        <>
            <Typography variant="h5">Hinzufügen</Typography>
            <Box
                display="flex"
                marginTop={3}
                marginBottom={4}
                maxWidth={500}
                justifyContent="space-between"
                margin="0px auto"
            >
                <MealCard onClick={() => handleNavigation(FoodType.snack)} type="snack" />
                <MealCard onClick={() => handleNavigation(FoodType.meal)} type="meal" />
                <MealCard onClick={() => handleNavigation(FoodType.drink)} type="drink" />
            </Box>
            <Typography variant="h5">Dein Tag</Typography>
            <DailyFoodListContainer day={new Date()} />
        </>
    )
}
