import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Checkbox,
    Typography,
    Divider,
} from '@material-ui/core'
import MaterialTable, { Column } from 'material-table'
import icons from '../../../utils/table'
import styled from 'styled-components'
import { materialTableLocalization } from '../../../utils/de'
interface FoodEntry {
    time: Date
    id: string
    type: string
    description: string
    picturePath: string | null
    hasPicture: boolean
    foodBrands: string | null
}
interface Props {
    username: string
    open: boolean
    onDismiss: () => void
    foodEntries: FoodEntry[]
    loading: boolean
}

const DetailWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        padding: 16px;
    }
`

export const ProbandDetailDialog: React.FC<Props> = ({
    open,
    onDismiss,
    foodEntries,
    username,
    loading,
}) => {
    const columns: Column<FoodEntry>[] = [
        { title: 'Datum', field: 'time', type: 'datetime' },
        { title: 'Typ', field: 'type' },
        { title: 'Beschreibung', field: 'description' },
        { title: 'Marken', field: 'foodBrands' },
        {
            title: 'Foto vorhanden?',
            field: 'hasPicture',
            render: (data) => (
                <Checkbox
                    color="primary"
                    style={{ cursor: 'default', padding: 0 }}
                    checked={data.hasPicture}
                />
            ),
        },
    ]
    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Proband {username}</DialogTitle>
            <Divider />

            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <MaterialTable
                        data={foodEntries}
                        title="Eingetragene Nahrungsaufnahme"
                        icons={icons as any}
                        localization={materialTableLocalization}
                        isLoading={loading}
                        detailPanel={(rowData) => (
                            <DetailWrapper>
                                {rowData.hasPicture ? (
                                    <img
                                        alt="foodImage"
                                        src={`${process.env.REACT_APP_THUMBNAIL_URL}/?src=/${rowData.picturePath}&w=100&h=100`}
                                    />
                                ) : (
                                    <Typography variant="body1">Kein Bild vorhanden</Typography>
                                )}
                            </DetailWrapper>
                        )}
                        columns={columns}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss} color="primary">
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
