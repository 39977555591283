import { Checkbox, Chip } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Block from '@material-ui/icons/Block'
import Delete from '@material-ui/icons/DeleteForever'
import Reset from '@material-ui/icons/SettingsBackupRestore'
import MaterialTable, { Column } from 'material-table'
import React, { useState } from 'react'
import styled from 'styled-components'
import { SimpleDialog } from '../../../components/Dialog/SimpleDialog'
import { getAdmins_getAdmins as Admin } from '../../../graphql/generated/getAdmins'
import { UserRole } from '../../../graphql/generated/globalTypes'
import { spacingLarge, spacingTiny } from '../../../styles/spacing'
import icons from '../../../utils/table'
import { materialTableLocalization } from '../../../utils/de'
import { CreateUserDialog } from '../../../components/Dialog/CreateUserDialog'

const TableWrapper = styled.div`
    margin-top: ${spacingLarge};
`

interface Props {
    admins: Admin[]
    isLoading: boolean
    resetProbandPassword: (id: string) => void
    createAdmin: (username: string) => void
    deleteAdmin: (id: string) => void
    removeAdminRole: (id: string, username: string) => void
}

export const AdminsTable: React.FC<Props> = ({
    isLoading,
    admins,
    resetProbandPassword,
    removeAdminRole,
    createAdmin,
    deleteAdmin,
}) => {
    const [showUserResetPasswordDialog, setShowUserResetPasswordDialog] = useState(false)
    const [showCreateAdminDialog, setShowCreateAdminDialog] = useState(false)
    const [showRemoveAdminRoleDialog, setShowRemoveAdminRoleDialog] = useState(false)
    const [showUserDeleteDialog, setshowUserDeleteDialog] = useState(false)
    const [rowUserData, setrowUserData] = useState<null | Admin>(null)

    const getColumns = (): Column<Admin>[] => [
        { title: 'Nutzername', field: 'username' },
        {
            title: 'Registriert',
            field: 'hasRegisteredWithOwnPassword',
            render: (data: Admin) => (
                <Checkbox
                    color="primary"
                    style={{ cursor: 'default' }}
                    checked={data.hasRegisteredWithOwnPassword}
                />
            ),
        },
        {
            title: 'Zugeordnete Studien',
            field: 'studyId',
            width: '50%',
            sorting: false,
            render: (data: Admin) => {
                return data?.managedStudies
                    ?.map((study) => study?.title)
                    .map((title) => (
                        <Chip
                            size="small"
                            style={{ marginRight: spacingTiny, marginBottom: 2 }}
                            label={title}
                            variant="outlined"
                        />
                    ))
            },
        },
        { title: 'UserId', field: 'userId', hidden: true },
    ]

    return (
        <div>
            <TableWrapper>
                <MaterialTable
                    localization={materialTableLocalization}
                    isLoading={isLoading}
                    data={admins}
                    title=""
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                    }}
                    icons={icons as any}
                    actions={[
                        {
                            icon: Add,
                            iconProps: { color: 'primary' },
                            tooltip: 'Administrator erstellen',
                            isFreeAction: true,
                            onClick: () => setShowCreateAdminDialog(true),
                        },
                        (rowData: Admin) => ({
                            icon: Block,
                            tooltip: 'Administrator-Rechte entfernen',
                            isFreeAction: false,
                            onClick: (event, data) => {
                                setrowUserData(data as Admin)
                                setShowRemoveAdminRoleDialog(true)
                            },
                        }),
                        (rowData: Admin) => ({
                            icon: Reset,
                            tooltip: 'Passwort zurücksetzen',
                            isFreeAction: false,
                            disabled: !rowData.hasRegisteredWithOwnPassword,
                            onClick: (event, data) => {
                                setrowUserData(data as Admin)
                                setShowUserResetPasswordDialog(true)
                            },
                        }),
                        {
                            icon: Delete,
                            tooltip: 'Administrator löschen',
                            isFreeAction: false,
                            onClick: (event, data) => {
                                setrowUserData(data as Admin)
                                setshowUserDeleteDialog(true)
                            },
                        },
                    ]}
                    columns={getColumns()}
                />
            </TableWrapper>
            <SimpleDialog
                title="Passwort zurücksetzen"
                onConfirm={() => {
                    if (rowUserData) {
                        resetProbandPassword(rowUserData.id)
                    }
                    setShowUserResetPasswordDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowUserResetPasswordDialog(false)}
                open={showUserResetPasswordDialog}
                text="Soll für diesen Administrator das Passwort zurückgesetzt werden?"
            />
            <SimpleDialog
                title="Admin Rechte entfernen"
                onConfirm={() => {
                    if (rowUserData) {
                        removeAdminRole(rowUserData.id, rowUserData.username)
                    }
                    setShowRemoveAdminRoleDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowRemoveAdminRoleDialog(false)}
                open={showRemoveAdminRoleDialog}
                text="Sollen diesem Administrator die Admin-Rechte entzogen werden?"
            />
            <SimpleDialog
                title={`Admin ${rowUserData?.username} löschen?`}
                onConfirm={() => {
                    if (rowUserData) {
                        deleteAdmin(rowUserData.id)
                    }
                    setshowUserDeleteDialog(false)
                }}
                confirmText="Löschen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setshowUserDeleteDialog(false)}
                open={showUserDeleteDialog}
                text={`Sind Sie sicher, dass der Administrator ${rowUserData?.username} gelöscht werden soll? Dieser Vorgang kann nicht rückgängig gemacht werden`}
            />
            <CreateUserDialog
                open={showCreateAdminDialog}
                role={UserRole.admin}
                placeholder="Nutzername"
                onDismiss={() => setShowCreateAdminDialog(false)}
                onConfirm={(username) => {
                    createAdmin(username)
                    setShowCreateAdminDialog(false)
                }}
            />
        </div>
    )
}
