import { FoodType, UserRole } from '../graphql/generated/globalTypes'
import { User } from '../models/User'
import { Page, AppBarItem } from '../state/ui-state'

export const FoodTypeConverter = (type: FoodType) => {
    switch (type) {
        case FoodType.drink:
            return 'Getränk'
        case FoodType.meal:
            return 'Mahlzeit'
        case FoodType.snack:
            return 'Snack'
    }
}

export const UserRoleConverter = (role: UserRole) => {
    switch (role) {
        case UserRole.admin:
            return 'Administrator'
        case UserRole.student:
            return 'Proband'
        case UserRole.root:
            return 'Root'
    }
}

export const pageToAppBarAction = (pageParam: Page): AppBarItem => {
    switch (pageParam) {
        case 'food-edit':
            return 'editFoodEntry'
        case 'food-new':
            return 'saveNewFoodEntry'
        default:
            return 'default'
    }
}

export const createImageDownloadLink = (imageId: string) =>
    `${process.env.REACT_APP_IMAGE_URL}/${imageId}`

export const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const removeGraphQlFromErrorMessage = (errorMessage: string) => {
    return errorMessage.replace('GraphQL error: ', '')
}

export const isAdmin = (user: User | null) => user?.role === UserRole.admin
export const isRoot = (user: User | null) => user?.role === UserRole.root
