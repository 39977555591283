import React from 'react'
import { HelpCard } from '../../components/HelpCard/HelpCard'
import { Typography } from '@material-ui/core'
import { useDrawerTitle } from '../../hooks/useDrawerTitle'
import styled from 'styled-components'
import packageJson from '../../../package.json'
import { Link } from 'react-router-dom'

const SmallParagraph = styled(Typography)`
    margin-bottom: 8px;
    margin-top: 8px;
`

export const HelpScreen = () => {
    useDrawerTitle('Hilfe')

    return (
        <>
            <HelpCard title="Kontakt">
                    <SmallParagraph>Institut für Humanernährung</SmallParagraph>
                    <SmallParagraph> Düsternbrooker Weg 17</SmallParagraph>
                    <SmallParagraph>24105 Kiel</SmallParagraph>
                    <a href="mailto:studie@nutrition.uni-kiel.de">studie@nutrition.uni-kiel.de</a>
            </HelpCard>

            <HelpCard title="So funktioniert's">
                    <SmallParagraph>
                        1. Trage möglichst alles ein, was Du über den Tag zu dir nimmst. Sowohl jede
                        Mahlzeit, als auch alle Snacks und Getränke sind wichtig für uns.
                    </SmallParagraph>
                    <SmallParagraph>
                        2. Für unsere Auswertung brauchen wir eine Beschreibung dessen, was Du zu
                        dir genommen hast. Hier kannst Du z.B. gerne auch Markennamen der
                        Lebensmittel angeben. Sollten die Zeichen für die Beschreibung nicht
                        ausreichen, lege ein neues Lebensmittel mit demselben Bild und zur gleichen
                        Uhrzeit an.
                    </SmallParagraph>
                    <SmallParagraph>
                        3. Fotos sind optional, helfen uns aber sehr bei der anschließenden
                        Auswertung. Hast Du kein Foto zur Hand, reicht auch eine detaillierte
                        Beschreibung.
                    </SmallParagraph>
                    <SmallParagraph>
                        4. Falls Du dich mal vertan hast oder vergessen hast etwas einzutragen,
                        kannst Du zum aktuellen und gestrigen Tag nachträglich Elemente hinzufügen
                        oder sie bearbeiten.
                    </SmallParagraph>
            </HelpCard>
            <HelpCard title="Datenschutz">
                <Typography variant="body1">
                    Du findest unsere Datenschutzerklärung <Link to="/datenschutz">hier</Link>. Alle deine Daten werden nur unter
                    deiner anonymisierten Probandennummer gespeichert.
                </Typography>
            </HelpCard>
            <HelpCard title="Impressum">
                <Typography variant="body1">
                    <Link to="/impressum">Hier</Link> findest du das Impressum.
                </Typography>
            </HelpCard>
            <Typography>Version {packageJson.version} </Typography>
        </>
    )
}
