import { useMutation, useQuery } from '@apollo/react-hooks'
import { CREATE_FOOD_MUTATION, DELETE_FOOD_MUTATION, UPDATE_FOOD_MUTATION } from '../mutations'
import { deleteFood, deleteFoodVariables } from '../generated/deleteFood'
import { getAllMyFood, getAllMyFood_getAllMyFood } from '../generated/getAllMyFood'
import { GET_ALL_MY_FOOD_QUERY, GET_FOOD_FROM_PROBAND } from '../queries'
import { updateFoodVariables, updateFood } from '../generated/updateFood'
import { useDispatch } from 'react-redux'
import { SetErrorNotificationAction, setErrorNotificationAction } from '../../actions/ui'
import { getFoodFromProband, getFoodFromProbandVariables } from '../generated/getFoodFromProband'
import { createFood, createFoodVariables } from '../generated/createFood'

export const useDeleteFood = () => {
    const [deleteFoodMutation] = useMutation<deleteFood, deleteFoodVariables>(
        DELETE_FOOD_MUTATION,
        {
            update: (cache, { data }) => {
                const existingFood = cache.readQuery<getAllMyFood>({
                    query: GET_ALL_MY_FOOD_QUERY,
                })

                if (data?.deleteFood.id && existingFood) {
                    cache.writeQuery<getAllMyFood>({
                        query: GET_ALL_MY_FOOD_QUERY,
                        data: {
                            getAllMyFood:
                                existingFood?.getAllMyFood?.filter(
                                    (food) => food.id !== data.deleteFood.id
                                ) || null,
                        },
                    })
                }
            },
        }
    )
    return [deleteFoodMutation]
}

export const useUpdateFood = () => {
    const [updateFoodMutation] = useMutation<updateFood, updateFoodVariables>(
        UPDATE_FOOD_MUTATION,
        {
            update: (cache, { data }) => {
                const existingFood = cache.readQuery<getAllMyFood>({
                    query: GET_ALL_MY_FOOD_QUERY,
                })

                if (data?.updateFood && existingFood) {
                    // convert Time to millis -> comes in ISO String
                    const updatedFoodEntry: getAllMyFood_getAllMyFood = {
                        ...data.updateFood,
                        time: new Date(data.updateFood.time).getTime().toString(),
                    }

                    cache.writeQuery<getAllMyFood>({
                        query: GET_ALL_MY_FOOD_QUERY,
                        data: {
                            getAllMyFood:
                                existingFood?.getAllMyFood?.map((food) =>
                                    food.id === data.updateFood.id
                                        ? { ...food, ...updatedFoodEntry }
                                        : { ...food }
                                ) || null,
                        },
                    })
                }
            },
        }
    )
    return [updateFoodMutation]
}

export const useCreateFood = () => {
    const [createFoodMutation] = useMutation<createFood, createFoodVariables>(
        CREATE_FOOD_MUTATION,
        {
            update: (cache, { data }) => {
                const existingFood = cache.readQuery<getAllMyFood>({
                    query: GET_ALL_MY_FOOD_QUERY,
                })

                if (data?.createFood) {
                    //convert Time to millis -> comes in ISO String
                    const newFoodEntry: getAllMyFood_getAllMyFood = {
                        ...data.createFood,
                        time: new Date(data.createFood.time).getTime().toString(),
                    }

                    cache.writeQuery<getAllMyFood>({
                        query: GET_ALL_MY_FOOD_QUERY,
                        data: {
                            getAllMyFood: [...existingFood!.getAllMyFood!, newFoodEntry],
                        },
                    })
                }
            },
        }
    )
    return [createFoodMutation]
}

export const useGetAllMyFood = () => {
    const dispatch = useDispatch()
    const { data, error } = useQuery<getAllMyFood>(GET_ALL_MY_FOOD_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(error?.message || 'Keine Daten gefunden!')
            ),
    })

    return data
}

export const useGetFoodFromProband = (probandId: string) => {
    const dispatch = useDispatch()
    const { data, error, loading } = useQuery<getFoodFromProband, getFoodFromProbandVariables>(
        GET_FOOD_FROM_PROBAND,
        {
            variables: {
                probandId,
            },
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(error?.message || 'Keine Daten gefunden!')
                ),
        }
    )

    return { data, loading }
}
