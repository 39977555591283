import { useMutation, useQuery } from '@apollo/react-hooks'
import { useDispatch } from 'react-redux'
import {
    SetErrorNotificationAction,
    setErrorNotificationAction,
    SetNotificationAction,
    setNotificationAction,
} from '../../actions/ui'
import { addProbandToStudy, addProbandToStudyVariables } from '../generated/addProbandToStudy'
import { createStudy, createStudyVariables } from '../generated/createStudy'
import { deleteStudy, deleteStudyVariables } from '../generated/deleteStudy'
import { getStudies } from '../generated/getStudies'
import { getStudiesWithImageMetadata } from '../generated/getStudiesWithImageMetadata'
import { ADD_PROBAND_TO_STUDY, CREATE_STUDY_MUTATION, DELETE_STUDY_MUTATION } from '../mutations'
import { GET_PROBANDS_WITH_FOOD_QUERY, GET_STUDIES_QUERY, GET_STUDIES_WITH_IMAGE_METADATA } from '../queries'

export const useGetStudies = () => {
    const dispatch = useDispatch()

    const { data, error, loading } = useQuery<getStudies>(GET_STUDIES_QUERY, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(error?.message || 'Keine Daten gefunden!')
            ),
    })

    return {data, loading}
}

export const useGetStudiesWithImageMetadata = () => {
    const dispatch = useDispatch()

    const { data, error, loading } = useQuery<getStudiesWithImageMetadata>(GET_STUDIES_WITH_IMAGE_METADATA, {
        onError: (err) =>
            dispatch<SetErrorNotificationAction>(
                setErrorNotificationAction(error?.message || 'Keine Daten gefunden!')
            ),
    })

    return {data, loading}
}

export const useCreateStudy = () => {
    const dispatch = useDispatch()
    const [createStudyMutation] = useMutation<createStudy, createStudyVariables>(
        CREATE_STUDY_MUTATION,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(err?.message || 'Fehler beim Erstellen der Studie!')
                ),
            onCompleted: () =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: 'Studie erfolgreich hinzugefügt',
                    })
                ),
            update: (cache, { data }) => {
                const existingStudies = cache.readQuery<getStudies>({
                    query: GET_STUDIES_QUERY,
                })

                if (data?.createStudy.id && existingStudies) {
                    cache.writeQuery<getStudies>({
                        query: GET_STUDIES_QUERY,
                        data: {
                            getStudies: [
                                ...(existingStudies?.getStudies || []),
                                { ...data.createStudy, managedBy: [] },
                            ],
                        },
                    })
                }
            },
        }
    )

    return createStudyMutation
}

export const useDeleteStudy = () => {
    const dispatch = useDispatch()
    const [deleteStudyMutation] = useMutation<deleteStudy, deleteStudyVariables>(
        DELETE_STUDY_MUTATION,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(err?.message || 'Fehler beim Löschen der Studie!')
                ),
            onCompleted: () =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: 'Studie erfolgreich entfernt',
                    })
                ),
            update: (cache, { data }) => {
                const existingStudies = cache.readQuery<getStudies>({
                    query: GET_STUDIES_QUERY,
                })

                if (data?.deleteStudy && existingStudies) {
                    cache.writeQuery<getStudies>({
                        query: GET_STUDIES_QUERY,
                        data: {
                            getStudies:
                                existingStudies.getStudies?.filter(
                                    (study) => study.id !== data?.deleteStudy
                                ) || [],
                        },
                    })
                }
            },
        }
    )

    return deleteStudyMutation
}

export const useAddProbandToStudy = () => {
    const dispatch = useDispatch()
    const [addProbandToStudyMutation] = useMutation<addProbandToStudy, addProbandToStudyVariables>(
        ADD_PROBAND_TO_STUDY,
        {
            onError: (err) =>
                dispatch<SetErrorNotificationAction>(
                    setErrorNotificationAction(
                        `Error: ${err?.message} Fehler beim Hinzufügen zur Studie!`
                    )
                ),
            onCompleted: () =>
                dispatch<SetNotificationAction>(
                    setNotificationAction({
                        severity: 'success',
                        text: 'Proband hinzugefügt!',
                    })
                ),
            refetchQueries: [{ query: GET_PROBANDS_WITH_FOOD_QUERY }],
        }
    )

    return addProbandToStudyMutation
}
