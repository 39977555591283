import * as React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
} from '@material-ui/core'
import { useState } from 'react'

export type Props = Readonly<{
    open: boolean
    onDismiss: () => void
    onConfirm: (title: string) => void
}>

export const CreateStudyDialog: React.FC<Props> = ({ open, onDismiss, onConfirm }) => {
    const [title, setTitle] = useState('')

    React.useEffect(() => {
        setTitle('')
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onDismiss}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Studie hinzufügen</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    onChange={(evt) => setTitle(evt.currentTarget.value)}
                    value={title}
                    placeholder="Studientitel"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onDismiss}>Abbrechen</Button>
                <Button onClick={() => onConfirm(title)} color="primary" autoFocus>
                    hinzufügen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
