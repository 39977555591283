import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import ArrowLeft from '@material-ui/icons/KeyboardBackspace'
import { useHistory } from 'react-router-dom'

const Wrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
`

export const BackButton: React.FC = () => {
    const history = useHistory()
    return (
        <Wrapper onClick={() => history.goBack()}>
            <ArrowLeft style={{ marginRight: '8px' }} />
            <Typography variant="h6">Zurück</Typography>
        </Wrapper>
    )
}
