import * as React from 'react'
import { DrawerLayout } from './DrawerLayout'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from '../../state/app-state'
import {
    SetDrawerTitleAction,
    SetAppBarItemClicked,
    SetErrorNotificationAction,
    setErrorNotificationAction,
} from '../../actions/ui'
import { AppBarItem, Page } from '../../state/ui-state'
import { me_me } from '../../graphql/generated/me'
import { useLogoutMutation } from '../../graphql/hooks/user'
import { LogoutCurrentUserAction, logoutCurrentUser } from '../../actions/data'
import { client } from '../..'

export const DrawerLayoutContainer: React.FC = ({ children }) => {
    const title = useSelector<AppState, string>((store) => store.ui.drawerTitle)
    const page = useSelector<AppState, Page>((store) => store.ui.location.page)
    const dispatch = useDispatch()
    const logout = useLogoutMutation()

    const setTitle = (titleName: string) =>
        dispatch<SetDrawerTitleAction>(SetDrawerTitleAction(titleName))

    const handleAppBarItemClick = (item: AppBarItem) =>
        dispatch<SetAppBarItemClicked>(SetAppBarItemClicked(item))

    const user = useSelector<AppState, me_me | null>((store) => store.data.user)

    const handleLogout = () => {
        logout()
            .then((res) => {
                if (res.data) {
                    dispatch<LogoutCurrentUserAction>(logoutCurrentUser())
                    //reset Cache
                    client.clearStore()
                }
            })
            .catch((err) =>
                dispatch<SetErrorNotificationAction>(setErrorNotificationAction(err.message))
            )
    }

    return (
        <DrawerLayout
            logout={handleLogout}
            handleAppBarItemClick={handleAppBarItemClick}
            title={title}
            page={page}
            setTitle={setTitle}
            user={user}
        >
            {children}
        </DrawerLayout>
    )
}
