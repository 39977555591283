import React from 'react'
import { UserRole } from '../../../graphql/generated/globalTypes'
import {
    useCreateUser,
    useGetAdmins,
    useResetPassword,
    useUpdateUser,
    useDeleteUser,
} from '../../../graphql/hooks/user'
import { GET_ADMINS_QUERY } from '../../../graphql/queries'
import { AdminsTable } from './AdminsTable'

export const AdminsTableContainer: React.FC = () => {
    const { data, loading } = useGetAdmins()
    const resetPassword = useResetPassword()
    const createUser = useCreateUser()
    const deleteAdmin = useDeleteUser()
    const updateUser = useUpdateUser()

    const handleResetPassword = (userId: string) => {
        resetPassword({
            variables: {
                userId,
            },
        })
    }

    const handleDeleteUser = (id: string) => {
        deleteAdmin({
            variables: {
                id,
            },
        })
    }

    const handleCreateAdmin = (username: string) => {
        createUser({
            variables: {
                username,
                role: UserRole.admin,
            },
            refetchQueries: [{ query: GET_ADMINS_QUERY }],
        })
    }

    const handleRemoveAdminRole = (userId: string, username: string) => {
        updateUser({
            variables: {
                id: userId,
                update: {
                    role: UserRole.student,
                    username: username,
                },
            },
        })
    }

    return (
        <AdminsTable
            createAdmin={handleCreateAdmin}
            resetProbandPassword={handleResetPassword}
            removeAdminRole={handleRemoveAdminRole}
            deleteAdmin={handleDeleteUser}
            isLoading={loading}
            admins={data?.getAdmins || []}
        />
    )
}
