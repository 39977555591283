import React from 'react'
import { UserRole } from '../../../graphql/generated/globalTypes'
import {
    useCreateUser,
    useDeleteUser,
    useGetRoots,
    useResetPassword,
    useUpdateUser,
} from '../../../graphql/hooks/user'
import { GET_ROOTS_QUERY } from '../../../graphql/queries'
import { RootsTable } from './RootsTable'

export const RootsTableContainer: React.FC = () => {
    const { data, loading } = useGetRoots()
    const resetPassword = useResetPassword()
    const createUser = useCreateUser()
    const deleteAdmin = useDeleteUser()
    const updateUser = useUpdateUser()

    const handleResetPassword = (userId: string) => {
        resetPassword({
            variables: {
                userId,
            },
        })
    }

    const handleDeleteUser = (id: string) => {
        deleteAdmin({
            variables: {
                id,
            },
            refetchQueries: [{ query: GET_ROOTS_QUERY }],
        })
    }

    const handleCreateRoot = (username: string) => {
        createUser({
            variables: {
                username,
                role: UserRole.root,
            },
            refetchQueries: [{ query: GET_ROOTS_QUERY }],
        })
    }

    const handleRemoveRootRole = (userId: string, username: string) => {
        updateUser({
            variables: {
                id: userId,
                update: {
                    role: UserRole.admin,
                    username: username,
                },
            },
            refetchQueries: [{ query: GET_ROOTS_QUERY }],
        })
    }

    return (
        <RootsTable
            createRoot={handleCreateRoot}
            resetPassword={handleResetPassword}
            removeRootRole={handleRemoveRootRole}
            deleteRoot={handleDeleteUser}
            isLoading={loading}
            roots={data?.getRoots || []}
        />
    )
}
