import * as React from 'react'
import { makeStyles, Card, CardActionArea, CardMedia, CardActions, Button } from '@material-ui/core'
import Meal from '../../assets/meal.jpg'
import Snack from '../../assets/snack.jpg'
import Drink from '../../assets/drink.jpg'

export type Props = Readonly<{
    type: 'snack' | 'meal' | 'drink'
    onClick: () => void
}>

interface MetadatesForType {
    title: string
    image: string
}

export const MealCard: React.FC<Props> = ({ type, onClick }) => {
    const useStyles = makeStyles({
        root: {
            width: 89,
        },
        media: {
            height: 89,
        },
    })

    const getMetadata = (): MetadatesForType => {
        switch (type) {
            case 'drink':
                return {
                    image: Drink,
                    title: 'Getränk',
                }
            case 'meal':
                return {
                    image: Meal,
                    title: 'Mahlzeit',
                }
            case 'snack':
                return {
                    image: Snack,
                    title: 'Snack',
                }
        }
    }

    const classes = useStyles()
    return (
        <Card onClick={onClick} className={classes.root}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={getMetadata().image}
                    title="Contemplative Reptile"
                />
            </CardActionArea>
            <CardActions>
                <Button size="small" fullWidth color="primary">
                    {getMetadata().title}
                </Button>
            </CardActions>
        </Card>
    )
}
