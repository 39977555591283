import { Checkbox } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Block from '@material-ui/icons/Block'
import Delete from '@material-ui/icons/DeleteForever'
import Reset from '@material-ui/icons/SettingsBackupRestore'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CreateUserDialog } from '../../../components/Dialog/CreateUserDialog'
import { SimpleDialog } from '../../../components/Dialog/SimpleDialog'
import { getRoots_getRoots as Root } from '../../../graphql/generated/getRoots'
import { UserRole } from '../../../graphql/generated/globalTypes'
import { spacingLarge } from '../../../styles/spacing'
import icons from '../../../utils/table'
import { materialTableLocalization } from '../../../utils/de'

const TableWrapper = styled.div`
    margin-top: ${spacingLarge};
    max-width: 800px;
`

interface Props {
    roots: Root[]
    isLoading: boolean
    resetPassword: (id: string) => void
    createRoot: (username: string) => void
    deleteRoot: (id: string) => void
    removeRootRole: (id: string, username: string) => void
}

export const RootsTable: React.FC<Props> = ({
    isLoading,
    roots,
    removeRootRole,
    resetPassword,
    createRoot,
    deleteRoot,
}) => {
    const [showUserResetPasswordDialog, setShowUserResetPasswordDialog] = useState(false)
    const [showCreateRootDialog, setShowCreateRootDialog] = useState(false)
    const [showRemoveRootRoleDialog, setShowRemoveRootRoleDialog] = useState(false)
    const [showUserDeleteDialog, setshowUserDeleteDialog] = useState(false)

    const [rowUserData, setrowUserData] = useState<null | Root>(null)

    return (
        <div>
            <TableWrapper>
                <MaterialTable
                    localization={materialTableLocalization}
                    isLoading={isLoading}
                    data={roots}
                    title=""
                    options={{
                        actionsColumnIndex: -1,
                    }}
                    icons={icons as any}
                    actions={[
                        {
                            icon: Add,
                            iconProps: { color: 'primary' },
                            tooltip: 'Root erstellen',
                            isFreeAction: true,
                            onClick: () => setShowCreateRootDialog(true),
                        },
                        (rowData: Root) => ({
                            icon: Block,
                            tooltip: 'Root-Rechte entfernen',
                            isFreeAction: false,
                            onClick: (event, data: Root) => {
                                setrowUserData(data)
                                setShowRemoveRootRoleDialog(true)
                            },
                        }),
                        (rowData: Root) => ({
                            icon: Reset,
                            tooltip: 'Passwort zurücksetzen',
                            isFreeAction: false,
                            disabled: !rowData.hasRegisteredWithOwnPassword,
                            onClick: (event, data: Root) => {
                                setrowUserData(data)
                                setShowUserResetPasswordDialog(true)
                            },
                        }),
                        {
                            icon: Delete,
                            tooltip: 'Root löschen',
                            isFreeAction: false,
                            onClick: (event, data: any) => {
                                setrowUserData(data)
                                setshowUserDeleteDialog(true)
                            },
                        },
                    ]}
                    columns={[
                        { title: 'Nutzername', field: 'username' },
                        {
                            title: 'Registriert',
                            field: 'hasRegisteredWithOwnPassword',
                            render: (data) => (
                                <Checkbox
                                    color="primary"
                                    style={{ cursor: 'default' }}
                                    checked={data.hasRegisteredWithOwnPassword}
                                />
                            ),
                        },
                        { title: 'UserId', field: 'userId', hidden: true },
                    ]}
                />
            </TableWrapper>
            <SimpleDialog
                title="Passwort zurücksetzen"
                onConfirm={() => {
                    if (rowUserData) {
                        resetPassword(rowUserData.id)
                    }
                    setShowUserResetPasswordDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowUserResetPasswordDialog(false)}
                open={showUserResetPasswordDialog}
                text="Soll für diesen Root-Nutzer das Passwort zurückgesetzt werden?"
            />
            <SimpleDialog
                title="Root Rechte entfernen"
                onConfirm={() => {
                    if (rowUserData) {
                        removeRootRole(rowUserData.id, rowUserData.username)
                    }
                    setShowRemoveRootRoleDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowRemoveRootRoleDialog(false)}
                open={showRemoveRootRoleDialog}
                text="Sollen diesem Root-Nutzer die Root-Rechte entzogen werden und Admin-Rechte zugeteilt werden?"
            />
            <SimpleDialog
                title={`Root ${rowUserData?.username} löschen?`}
                onConfirm={() => {
                    if (rowUserData) {
                        deleteRoot(rowUserData.id)
                    }
                    setshowUserDeleteDialog(false)
                }}
                confirmText="Löschen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setshowUserDeleteDialog(false)}
                open={showUserDeleteDialog}
                text={`Sind Sie sicher, dass der Root-Nutzer ${rowUserData?.username} gelöscht werden soll? Dieser Vorgang kann nicht rückgängig gemacht werden`}
            />
            <CreateUserDialog
                open={showCreateRootDialog}
                role={UserRole.root}
                onDismiss={() => setShowCreateRootDialog(false)}
                placeholder="Nutzername"
                onConfirm={(username) => {
                    createRoot(username)
                    setShowCreateRootDialog(false)
                }}
            />
        </div>
    )
}
