import React from 'react'
import { Paper, Box, Typography } from '@material-ui/core'
import { Title } from '../../screens/Help-Login/HelpLoginScreen'
import { BackButton } from '../../components/BackButton/BackButton'
import styled from 'styled-components'
import { spacingMedium } from '../../styles/spacing'

const Headline = styled(Typography)`
    font-weight: bold;
    margin-top: ${spacingMedium};
`

const Background = styled(Paper)`
    padding: ${spacingMedium};
`

export const ImpressumScreen: React.FC = () => {
    return (
        <Box maxWidth={700} padding={1} margin="0 auto" marginTop={2}>
            <BackButton />
            <Title variant="h4">Impressum</Title>
            <Background>
                <Headline style={{ marginTop: 0 }} variant="body1">
                    Vertreten durch
                </Headline>
                <Typography variant="body1">Prof. Dr. Dr. Anja Bosy-Westphal</Typography>
                <Headline variant="body1">Kontakt</Headline>
                <Typography variant="body1">Telefon: 0431 8805671</Typography>
                <Typography variant="body1">
                    E-Mail:
                    <a href="mailto:studie@nutrition.uni-kiel.de">studie@nutrition.uni-kiel.de</a>
                </Typography>
                <Headline variant="body1">Haftungsausschluss</Headline>
                <Typography variant="body1">
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
                    Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine
                    Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§
                    8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
                    übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach
                    den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
                    ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                    möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                    diese Inhalte umgehend entfernen.
                </Typography>
            </Background>
        </Box>
    )
}
