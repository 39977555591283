import * as React from 'react'
import { AppState } from '../../state/app-state'
import { useSelector } from 'react-redux'
import { ProtectedRoute } from './ProtectedRoute'
import { RouteProps } from 'react-router-dom'
import { me_me } from '../../graphql/generated/me'

interface Props extends RouteProps {
    onlyAdmin?: boolean
    onlyRoot?: boolean
}

export const ProtectedRouteContainer: React.FC<Props> = ({ ...rest }) => {
    const user = useSelector<AppState, me_me | null>((store) => store.data.user)

    return <ProtectedRoute user={user} {...rest} />
}
