import { Fade, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import MaterialTable from 'material-table'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
    getStudies_getStudies,
    getStudies_getStudies_managedBy as Admin,
} from '../../../graphql/generated/getStudies'
import { spacingLarge } from '../../../styles/spacing'
import { materialTableLocalization } from '../../../utils/de'
import Block from '@material-ui/icons/Block'
import icons from '../../../utils/table'
import { SimpleDialog } from '../../../components/Dialog/SimpleDialog'
import { AddAdminToStudyDialogContainer } from '../../../components/Dialog/AddAdminToStudy/AddAdminToStudyDialog.container'

const TableWrapper = styled.div`
    margin-top: ${spacingLarge};
    max-width: 800px;
`

interface Props {
    study: getStudies_getStudies | undefined
    removeAdminFromStudy: (adminId: string, studyId: string) => void
}

export const ManagedBy: React.FC<Props> = ({ study, removeAdminFromStudy }) => {
    const { id } = useParams<{ id: string | undefined }>()

    const [showAdminRemoveFromStudyDialog, setShowRemoveFromStudyDialog] = useState(false)
    const [showAdminAddToStudyDialog, setShowAddToStudyDialog] = useState(false)
    const [rowUserId, setrowUserId] = useState<null | string>(null)

    return (
        <div>
            <Typography style={{ marginTop: spacingLarge }} variant="h5">
                Administratoren mit Zugriff auf Studie
            </Typography>
            <Fade in={!!id}>
                <TableWrapper>
                    <MaterialTable
                        data={study?.managedBy || []}
                        title={``}
                        icons={icons as any}
                        options={{
                            actionsColumnIndex: -1,
                        }}
                        localization={{
                            ...materialTableLocalization,
                            body: {
                                ...materialTableLocalization.body,
                                emptyDataSourceMessage: 'Kein AdminstratorIn mit Zugriff',
                            },
                        }}
                        actions={[
                            {
                                icon: Add,
                                tooltip: 'AdminstratorIn hinzufügen',
                                isFreeAction: true,
                                onClick: () => setShowAddToStudyDialog(true),
                            },
                            (rowData: Admin) => ({
                                icon: Block,
                                tooltip: 'Zugriff auf Studie entfernen',
                                isFreeAction: false,
                                onClick: (event) => {
                                    setrowUserId(rowData.id)
                                    setShowRemoveFromStudyDialog(true)
                                },
                            }),
                        ]}
                        columns={[{ title: 'Username', field: 'username' }]}
                    />
                </TableWrapper>
            </Fade>
            <SimpleDialog
                title="Zugriff entfernen"
                onConfirm={() => {
                    if (rowUserId && study) {
                        removeAdminFromStudy(rowUserId, study.id)
                    }
                    setShowRemoveFromStudyDialog(false)
                }}
                confirmText="Entfernen"
                confirmColor="secondary"
                dismissText="Abbrechen"
                onDismiss={() => setShowRemoveFromStudyDialog(false)}
                open={showAdminRemoveFromStudyDialog}
                text="Soll der Zugriff des AdministratorIn auf die Studie entfernt werden?"
            />
            {study && (
                <AddAdminToStudyDialogContainer
                    open={showAdminAddToStudyDialog}
                    onDismiss={() => setShowAddToStudyDialog(false)}
                    studyId={study?.id}
                    onConfirm={() => setShowAddToStudyDialog(false)}
                />
            )}
        </div>
    )
}
